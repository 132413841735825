.spp-btns {
    $m: $val-xs-1;
    display: flex;
    flex-wrap: wrap;
    margin-right: -$m;
    margin-bottom: -$m;
    & > * {
        flex-grow: 1;
        min-width: auto;
        margin-right: $m;
        margin-bottom: $m; } }
