@mixin ObjectState-modile {
    &:not(.warning) {
        .warning {
            display: none; } }

    &__header {
        position: relative;

        .ObjectsState__grid {
            padding: $val-md $val-xs-1;
            .name {
                .desktop {
                    display: none; }
                .mobile {
                    display: block; } } } } }

.ObjectState {
    font-family: $ff-title;
    overflow: hidden;
    margin-top: 4px;

    // Состояния

    &_gray:not(.selected) {
        background-color: $c-light-md; }

    &_gray,
    &.selected {
        .Song__List .item:nth-child(2n+1) {
            background-color: $c-light; } }

    &:not(&_gray):not(.selected) {
        .Song__List .item:nth-child(2n+1) {
            background-color: $c-light-md; } }

    &_gray.name-fade:not(.selected) {
        .ObjectsState__grid > .name {
            &::after {
                background: rgb(255,255,255);
                background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f6f6f8",GradientType=1); } } }
    &.name-fade:not(&_gray) {
        .ObjectsState__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); } }
    &.name-fade.selected {
        .ObjectsState__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff3e8",GradientType=1); } }
    &.name-fade.highlighted:not(.selected) {
        .ObjectsState__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff6ed",GradientType=1); } }

    // Элементы
    &__header {
        position: relative;
        .ButtonCircle {
            border-width: 1px;
            font-weight: 500;
            height: 40px;
            width: 40px; }
        .ObjectsState__grid {
            padding: 8px 12px;
            // padding: $val-xs $val-mr
            & > * {
                position: relative;
                z-index: 3; }

            .id {
                display: flex;
                font-size: $fz-sm;
                color: $c-dark-lg;
                font-weight: $fw-normal; }

            .name {
                z-index: 5;
                display: flex;
                font-size: 0.813rem;
                font-weight: 500;
                // justify-content: center
                & > * {
 } }                    // margin-top: -2px
            .sync_time {
                position: relative;
                margin-bottom: 1px;
                h4 {
                    font-size: $fz-sm;
                    color: $c-dark;
                    font-weight: $fw-bold;
                    margin: 0; }
                .desktop {
                    display: block; }
                .mobile {
                    display: none; }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 30px; } }

            .sync_start_time {
                display: flex;
                // justify-content: center
                color: $c-dark;
                font-size: 0.813rem;
                font-weight: $fw-normal;
                text-align: left;
                cursor: default; } }

 }        // Ползунок

    &__List {
        margin: $val-mr;
        margin-top: 0;
        margin-right: $val-xs-1 + 1px;

        .ScrollContainer__more {
            margin-top: $val-sm-1;
            padding-bottom: $val-lg - $val-mr; }

        .item {
            display: flex;
            align-items: center;
            padding: $val-mr $val-sm-1;

            .number,
            .from,
            .date,
            .plays {
                font-size: $fz-sm;
                font-weight: $fw-normal; }

            .number,
            .from,
            .plays {
                color: $c-dark-lg; }

            .date {
                color: $c-dark; }

            .number {
                width: 28px; }

            .from {
                margin-right: $val-mr;
                span {
                    display: none; } }

            .date {
                margin-right: $val-sm-1; } } }

    audio {
        display: none; }

    @include media-breakpoint-down(lg) {
        @include track-modile; } }
