
.range-slider {
    width: 480px;
    margin: 24px auto 0;

  }
  
  .range-slider.margin-lg {
    margin: 42px auto 0;
  }
  
  /* Yellow Range Slider */
  #range-slider-pink {
    height: 6px !important;
    background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")
      #333;
  }
  
  #range-slider-pink .range-slider__range {
    background: #ff334790;
    transition: height 0.3s;
  }
  
  #range-slider-pink .range-slider__thumb {
    background: #ff3347;
    transition: transform 0.3s;
    width: 18px;
    height: 18px;
  }
  
  #range-slider-pink .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
  }
  
  #range-slider-pink .range-slider__range[data-active] {
    height: 16px;
  }
  
  /* AB Range Slider */
  #range-slider-ab {
    height: 16px;
    background: #4b4d61;
  }
  
  #range-slider-ab .range-slider__range {
    background: #ff4141;
  }
  
  #range-slider-ab .range-slider__thumb {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  
  #range-slider-ab .range-slider__thumb[data-lower] {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M11,7A2,2 0 0,0 9,9V17H11V13H13V17H15V9A2,2 0 0,0 13,7H11M11,9H13V11H11V9Z' /%3E%3C/svg%3E")
      #ff4141;
  }
  
  #range-slider-ab .range-slider__thumb[data-upper] {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M15,10.5V9A2,2 0 0,0 13,7H9V17H13A2,2 0 0,0 15,15V13.5C15,12.7 14.3,12 13.5,12C14.3,12 15,11.3 15,10.5M13,15H11V13H13V15M13,11H11V9H13V11Z' /%3E%3C/svg%3E")
      #ff4141;
  }
  
  #range-slider-ab .range-slider__thumb[data-lower][data-active] {
    animation: rotate-anti-clockwise 0.9s infinite;
  }
  
  #range-slider-ab .range-slider__thumb[data-upper][data-active] {
    animation: rotate-clockwise 0.9s infinite;
  }
  
  @keyframes rotate-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes rotate-anti-clockwise {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  
  /* Gradient Range Slider */
  #range-slider-gradient {
    height: 24px;
    border-radius: 12px;
    background: #353644;
  }
  
  #range-slider-gradient .range-slider__thumb {
    border: 6px solid #fff;
    box-sizing: border-box;
  }
  
  #range-slider-gradient .range-slider__thumb[data-lower] {
    background: #0073e6;
  }
  
  #range-slider-gradient .range-slider__thumb[data-upper] {
    background: #ee2c2c;
  }
  
  #range-slider-gradient .range-slider__range {
    background: linear-gradient(to right, #0073e6, #ee2c2c);
    background-size: 200% 100%;
    background-position: 50% 0;
  }
  
  #range-slider-gradient .range-slider__range[data-active],
  #range-slider-gradient
    .range-slider__thumb[data-active]
    ~ .range-slider__range {
    animation: move-bg 0.75s infinite linear;
  }
  
  @keyframes move-bg {
    0% {
      background-position: 50% 0;
    }
  
    25% {
      background-position: 100% 0;
    }
  
    50% {
      background-position: 50% 0;
    }
  
    75% {
      background-position: 0% 0;
    }
  
    100% {
      background-position: 50% 0;
    }
  }
  
  /* Range Slider as a Single Thumb Slider */
  .single-thumb .range-slider__thumb[data-lower] {
    width: 0;
  }
  .single-thumb .range-slider__range {
    border-radius: 6px;
  }
  
  /* Vertical Range Slider */
  .vertical-slider[data-vertical] {
    height: 180px;
  }
  