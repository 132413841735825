.ModalConfirm {

    .title {
        font-family: $ff-title;
        font-weight: $fw-normal;
        font-size: $fz-lg;
        color: $c-dark-lg;
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: $fz-xl; } } }
