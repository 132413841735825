.All_media {
    display: flex; }
.Playlists__grid {
    display: flex;
    align-items: center;
    height: 45px;
    @include media-breakpoint-down(sm) {
        height: 80px; }

    .tracks_count {
        // width: 90px
        width: 15%;
        // min-width: 65px
        white-space: nowrap;
        font-weight: 400;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }

    .tracks_count_public {
        // width: 90px
        width: 20%;
        // min-width: 65px
        white-space: nowrap;
        font-weight: 400;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }
    .play {
        width: 1px;
        margin-left: 9px;
        min-width: 1px; }

    .name {
        width: 45%;
        max-width: 400px;
        overflow: hidden; }
    .name_public {
        width: 40%;
        max-width: 400px;
        overflow: hidden; }
    .size {
        // width: 90px
        width: 15%;
        // min-width: 65px
        font-weight: 300;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }
    .size_public {
        // width: 90px
        width: 20%;
        // min-width: 65px
        font-weight: 300;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }

    .ended {
        width: 20px;
        min-width: 20px; }
    .open {
        width: 20px;
        min-width: 20px;
        margin-left: auto; }
    .duration {
        width: 25%;
        font-weight: 300;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }
    .duration_public {
        width: 20%;
        font-weight: 300;
        margin-left: auto;
        text-align: left !important;
        @include media-breakpoint-down(xl) {
            button span {
                display: none; } } }

    & > *:not(:last-child) {
        margin-right: $val-sm-1; } }
