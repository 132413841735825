.ModalAdsEdit {

    @include media-breakpoint-up(lg) {
        .ModalAdsDoubleGrid__left {
            & > *:not(.ModalAdsDoubleGrid__title):not(:last-child) {
                margin-bottom: $val-md-1; } }

        .FormInputText__comment {
            display: none; }

        .Uploader {
            margin-top: $val-lg-1; } }

    @include media-breakpoint-down(md) {
        .ModalAdsDoubleGrid {
            display: flex;
            flex-direction: column-reverse;
            .ModalAdsDoubleGrid__right {
                margin-bottom: $val-mg-1; }
            .ModalAdsDoubleGrid__left {
                margin-bottom: 0; } }

        .ModalAdsDoubleGrid__left {
            & > *:not(.ModalAdsDoubleGrid__title):not(:last-child) {
                margin-bottom: $val-md-1; } }

        .ModalAdsDoubleGrid__right {
            .Uploader {
                margin-bottom: $val-mg-1; } } } }
