.form-check {

    &-input {
        display: none; }

    &-input:disabled + &-label {
        pointer-events: none;
        color: $c-dark-lg;
        opacity: $o-xs; }

    &-label {
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        cursor: pointer;
        color: $c-dark-lg;
        &::before {
            content: '';
            display: inline-block;
            width: $val-sm-1;
            height: $val-sm-1;
            margin-right: $val-xs;
            margin-bottom: -.25rem;
            background-image: url(/assets/icons/check_disabled.svg);
            background-size: cover;
            background-repeat: no-repeat; }

        &:active {
            color: $c-dark;
            &::before {
                opacity: 1; } } }

    &-input:not(:checked) + &-label:hover {
        &::before {
            opacity: $o-xl; } }

    &-input:checked + &-label {
        color: $c-dark;
        &::before {
            background-image: url(/assets/icons/check_enabled.svg); } }

    // Отступы

    padding-left: 0;

    &:not(:last-child) {
        margin-bottom: $val-xs; }
    &:last-child {
        margin-bottom: 0; }

    &-margin-normalize {
        margin-bottom: -$val-xs; }

    &-inline {
        &:not(:last-child) {
            margin-right: $val-md-1; } } }
