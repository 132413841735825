.Alert {
    position: fixed;
    z-index: $z-index-elements;
    display: inline-block;
    border: $bw-sm solid transparent;
    border-radius: $br-md;
    text-align: center;
    font-family: $ff-title;
    font-size: $fz-md;
    font-wight: $fw-normal;

    @include media-breakpoint-up(lg) {
        top: $val-mg-3;
        left: 50%;
        transform: translateX(-50%);
        width: 800px;
        padding: $val-sm-2; }

    @include media-breakpoint-down(md) {
        top: $val-lg-1;
        left: $val-sm-2;
        right: $val-sm-2;
        padding: $val-sm-2; } }

.AlertError {
    background-color: rgba(#D66974, .92);
    border-color: #FFBAC0;
    color: #fff; }

.AlertWarning {
    background-color: rgba(#fffdcd, .92);
    border-color: #fff538;
    color: #fff;
    margin-top: 20px; }

.AlertSuccess {
    background-color: rgba(#4ed479, .92);
    border-color: #d0f1da;
    color: #fff; }

.AlertInfo {
    margin-top: 60px;
    background-color: #595980;
    border-color: #fff;
    border-radius: 8px;
    color: #fff; }
