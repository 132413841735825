.Space {
    background-color: $c-light-md;
    position: relative;
    min-height: calc(100vh - 300px);
    &::after {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 300px;
        background-color: $c-light-md; }

    &__wrap {
        position: relative;
        display: flex;
        justify-content: space-between;
        & > * {
            transition: .25s;
            width: (100% / 3); } }
    &__wrap-content {
        position: relative;
        display: flex;
        justify-content: space-between;
        // & > *
        //     transition: .25s
        //     width: (100% / 3)
        &> :first-child {
            transition: .25s;
            width: (100% / 3); }
        &> :last-child {
            transition: .25s;
            width: (100% / 3)*2; } }
    &__Place {
        &.disabled {
            opacity: $o-lg;
            cursor: default;

            * {
                cursor: default !important; } } }

    .ObjectsMenu {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $c-light;
        @each $size, $key in $container-offset-sizes {
            @include media-breakpoint-only($size) {
                left: -#{map-get($container-offset-sizes, $size)}; } } }

    .SlideMenu {
        @each $size, $key in $container-offset-sizes {
            @include media-breakpoint-only($size) {
                margin-right: -#{map-get($container-offset-sizes, $size)};
                margin-left: -#{map-get($container-offset-sizes, $size)};
                padding-right: #{map-get($container-offset-sizes, $size)};
                padding-left: #{map-get($container-offset-sizes, $size)}; } } }

    @include media-breakpoint-up(lg) {
        &__Place {
            position: relative;
            min-width: 190px;
            padding: $val-md-2 $val-md-1;

            &:last-child {
                padding-right: 0; }
            &:first-child {
                padding-top: $val-md-2 + ($val-xl - $val-xs);
                padding-left: 0; }
            &:not(:last-child) {
                border-right: $bw-xs solid $c-light; }

            & > *:not(:last-child) {
                margin-bottom: $val-md-2; }

            // Состояния

            &.not-expanded {
                width: 15%;
                & > * {
                    display: none; } }
            &.expanded {
                width: 200%; }
            &.not-expanded-content {
                // \width: 15%
                & > * {
                    display: none; } }
            &.expanded-content {
                width: 200%; } }

        .SlideMenu {
            display: none; } }

    @include media-breakpoint-down(md) {
        &__Place {
            padding-top: $val-mg-1;
            padding-bottom: $val-mg-2;
            width: 100% !important;
            &:not(.active) {
                display: none; } }

        .ObjectsMenu {
            display: none; }

        .SlideMenu {
            margin-bottom: $val-xl-1 + ($val-xs+$bw-sm); } } } // Прибавляем отступ полосы, позиционированной абсолютно
