.FormInputPassword {

    &__icon-password {
        cursor: pointer;
        z-index: 3 !important; }

    &_showing &__icon-password {
        @include set-svg-color(dark); }

    &:not(&_showing) &__icon-password {
        @include set-svg-color(dark-lg); }

    &__comment {
        font-family: $ff-title;
        color: $c-dark-lg;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        margin-top: $val-sm-2;

 } }        // @include media-breakpoint-up(lg)


