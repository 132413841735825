.SystemsReport__grid {
    display: flex;
    align-items: center;
    height: 15px;
    @include media-breakpoint-down(sm) {
        height: 28px; }

    .timestamp {
        width: 100%;
        max-width: 150px;
        white-space: nowrap;
        font-family: "Inconsolata"; }
    .event {
        text-align: left;
        width: 100%;
        overflow: hidden;
        font-family: "Inconsolata";
        font-weight: 500; }

    & > *:not(:last-child) {
        margin-right: $val-sm-1; } }
