.SettingsGroup {
    position: relative;
    & + & {
        margin-top: $val-md-1; }

    &:not(&_not-divider) {
        padding-bottom: $val-md-1;
        &:not(:last-child) {
            border-bottom: $bw-xs solid $c-dark-xs; } }
    &_not-divider + .SettingsGroup_title {
        margin-top: $val-md-1 !important; }

    &.SettingsGroup_title {
        padding-top: $val-md-1;
        margin-top: 0; }

    &_wrap &__items {
        & > *:not(:last-child) {
            margin-bottom: $val-md-1; } }

    &.disabled {
        opacity: $o-md;
        pointer-events: none;
        cursor: default; }

    // Равномерная авто-ширина .item-мов

    &:not(&_wrap):not([class*="columns-"]) &__items {
        display: flex;
        & > * {
            width: 100%;
            &:not(:last-child) {
                margin-right: 10%; } } }

    // columns

    &[class*="columns-"]:not(&_wrap) &__items {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10%;
        margin-bottom: -($val-md-1);
        & > * {
            margin-right: 10%;
            margin-bottom: $val-md-1; } }
    &_columns-1:not(&_wrap) &__items {
        & > * {
            width: 90%; } }
    &_columns-2:not(&_wrap) &__items {
        & > * {
            width: 40%; } }
    &_columns-3:not(&_wrap) &__items {
        & > * {
            width: 23.333333%; } }
    &_columns-4:not(&_wrap) &__items {
        & > * {
            width: 15%; } }
    &_columns-5:not(&_wrap) &__items {
        & > * {
            width: 10%; } }
    &_columns-6:not(&_wrap) &__items {
        & > * {
            width: 6.666666%; } }

    // Выравнивание содержимого .item-мов по высоте

    &_align-height {
        .SettingsGroupItem {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between; } }

    // Компоненты

    &__title {
        @include divider;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        .title,
        .type-title {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            background-color: $c-light-md;
            padding-right: $val-mr;
            transform: translateY(-50%);
            font-family: $ff-title; }
        .title {
            font-size: $fz-md;
            font-weight: $fw-bold;
            color: $c-dark;
            @include media-mobile {
                font-size: $fz-lg; } }
        .type-title {
            font-size: $fz-xs;
            font-weight: $fw-normal;
            text-transform: uppercase;
            color: $c-dark-lg; }

        @include media-mobile {
            background-color: $c-light-md; } }

    &__subtitle {
        font-size: $fz-xs;
        font-weight: $fw-normal;
        color: $c-dark-lg;
        font-style: italic;
        text-transform: none;
        top: 11px;
        position: relative; }

    &__comment {
        font-family: $ff-title;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        color: $c-dark-md;
        margin-top: $val-sm-2; } }
