.spp-svg {
    transition: .2s;
    &.rotate {
        transform: rotate(180deg); } }


@each $size, $value in $spacers {
    .spp-svg-size-#{$size} {
        @include set-svg-size($size); } }

@each $variant, $color in $theme-colors {
    .spp-svg-color-#{$variant} {
        @include set-svg-color($variant); } }

.spp-svg-auto {
    @include set-svg-size-auto; }
