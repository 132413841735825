.Modal_SettingsRole_description {
    font-size: 20px; }
.Modal_SettingsRole_body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_element {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: center;
        gap: 16px;
        & > *:first-child {
            flex: 1; }
        &_check {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            justify-content: center;
            & > *:first-child {
                flex: 1; }
            h2 {
                margin-bottom: 0 !important; }
            .form-check {
                margin-bottom: 0 !important; } } } }
.SettingsRoles {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__scroll {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 36vh;
        overflow-y: scroll;
        padding: 6px;
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
            background-color: $c-dark-sm;
            border-radius: 500px; }
        &::-webkit-scrollbar-track-piece {
            background: $c-light;
            border-radius: 500px; }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0; }
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px; } }
    &__editrole {
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        background-color: #ffffff;
        border-radius: 4px;
        padding-bottom: 14px;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // margin-bottom: 12px
        gap: 24px;
        & > *:first-child {
            flex: 1; }
        .eye {
            cursor: pointer;
            z-index: 2 !important;
            svg {

                height: 14px !important;
                & > * {
                    fill: #B7B7B7; } } }
        .spp-svg {
            z-index: 2 !important; }
        .eye-shown {
            cursor: pointer;
            z-index: 1000;
            svg {
                & > * {
                    fill: black; } } } }
    &__newrole_parent {
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        background-color: #ffffff;
        border-radius: 4px;
        padding-bottom: 24px;
        padding-top: 24px;
        padding-left: 20px;
        padding-right: 20px; }
    &__newrole {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        gap: 24px;
        .eye {
            cursor: pointer;
            z-index: 2 !important;
            svg {

                height: 14px !important;
                & > * {
                    fill: #B7B7B7; } } }
        .spp-svg {
            z-index: 2 !important; }
        .eye-shown {
            cursor: pointer;
            z-index: 1000;
            svg {
                & > * {
                    fill: black; } } }
        & > *:first-child {
            flex: 1; }
        &_password {
            flex: 1; }
        &_select {
            flex: 1; }
        // margin-top: 36px
        // width: 100%
        // display: flex
        // flex-direction: row
        // flex-wrap: wrap
        // gap: 100px
        &_edit_rights {
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            align-content: space-between;
            .btn {
                // min-width: 120px !important
 } } }                // width: 100px !important
    &_spinner {
        margin-top: 10%; }
    &_one {
        gap: 20px;
        display: flex;
        flex: 1;
        flex-direction: column; }
    &_two {
        gap: 20px;
        display: flex;
        flex: 1;
        flex-direction: column; }

    &_elements_row {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 16px;
        // justify-content: space-between
        align-items: end;
        & > *:first-child {
            flex: 1; } } }
