.ModalDoubleGrid {
    &__title {
        font-family: $ff-title;
        font-size: $fz-xl;
        font-weight: $fw-bold;
        color: $c-dark; }

    .info.c-prim {
            color: #ff3347; }

    @include media-breakpoint-up(lg) {
        display: flex;

        &__left {
            width: calc(70% - #{$val-xl-1});
            margin-right: $val-xl-1; }
        &__right {
            width: 30%; }

        &__title {
            margin-bottom: $val-lg; }

        &__info {
            &:not(:last-child) {
                margin-bottom: $val-md-1; }
            .label {
                margin-bottom: $val-xs-1; }
            .info {
                font-family: $ff-title;
                font-weight: $fw-normal;
                font-size: $fz-sm;
                color: $c-dark; } } }

    @include media-breakpoint-down(md) {
        &__title {
            margin-bottom: $val-xl-2; }

        &__left {
            margin-bottom: $val-mg-1; }

        &__info {
            &:not(:last-child) {
                margin-bottom: $val-md-1; } } } }
