.CollapsibleCardPlaylist {
    @include box-shadow($bs-md rgba($c-forth, $o-mr));
    // padding: $val-xs-1
    padding-left: 4px;
    padding-right: 4px;
    background-color: $c-light;
    overflow: hidden;
    margin: {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important; }

    &:last-child {
        margin-bottom: 0 !important; }

    &.selected &__content {
        & > .ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content > * {
            margin-bottom: $bw-sm;
            &:first-child {
                margin-top: 0; }
            &:not(.selected) {
                background-color: $c-light; }
            &.selected + .selected {
                margin-top: -$bw-sm; } } }

    &.opened &__header .spp-svg {
        transform: rotate(180deg); }
    &:not(.opened) &__content {
        display: none; }

    &__header {

        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__title {
        width: 100%;
        cursor: pointer;
        font-family: $ff-title;
        font-weight: $fw-bold; }

    &__open .spp-svg {
        transition: .2s;
        cursor: pointer; }

    &__Empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark-lg; }

    // Адаптив

    @include media-breakpoint-up(lg) {
        border-radius: $br-md;
        &:not(:last-child) {
 }            // margin-bottom: $bw-sm !important

        &.selected {
            border-color: $c-light; }
        &:not(.selected) {
            border: $bw-xs solid $c-light-sm; }

        &__title {
            font-size: $fz-sm;
            color: $c-dark-lg; }
        &__content {
            margin-top: $val-xs-1;
            margin-right: 6px; }

        &__content > .ScrollContainer > .ScrollContainer__more {
            margin-bottom: $val-lg; } }

    @include media-breakpoint-down(md) {
        border-radius: $br-lg-2;
        // padding: ($val-md-2 - $bw-md) ($val-sm-2 - $bw-md)
        padding-bottom: 0;

        &:not(:last-child) {
 }            // margin-bottom: $val-mr !important

        &.selected {
            border: $bw-md solid $c-light; }
        &:not(.selected) {
            border: $bw-md solid $c-light; }

        &__header {
 }            // margin-bottom: ($val-md-2 - $bw-md)
        &__title {
            font-size: $fz-sm;
            color: $c-dark; }
        &__open {
            svg {
                height: $val-sm-1 !important; } }

        &__content > .ScrollContainer {
            & > .ScrollContainer__background {
                margin-bottom: $val-lg; }
            & > .ScrollContainer__more {
                margin-bottom: ($val-lg - $bw-md); } } } }
