.ObjectVolumeGroupVolumeList {

    &__item {
        @include media-tablet-and-up {
            @include volume-item-desktop; }
        @include media-mobile {
            @include volume-item-mobile; } }

    @include media-mobile {
        @include volume-list-item-mobile; } }
