.FormDatepickerSingle {
    font-family: $ff-title;

    $date-fz-lg: $fz-xs;
    $date-fz-md: $fz-lg;
    $date-fz-sm: $fz-lg;

    $selected-padding-lg: 7px;
    $selected-padding-md: 29px;
    $selected-padding-sm: 20px;

    $date-width-lg: 16px;
    $date-width-md: 18px;
    $date-width-sm: 18px;

    $date-margin-lg: $val-sm-2;
    $date-margin-md: $val-xl;
    $date-margin-sm: $val-md-1;

    $row-margin-lg: $val-sm;
    $row-margin-md: $val-xl-1;
    $row-margin-sm: $val-lg-1;

    &__title {
        margin-bottom: $val-md-3;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        text-transform: uppercase;
        color: $c-dark-lg;
        text-align: center; }

    &__selects {
        display: flex;
        justify-content: space-between;
        & > *:first-child {
            width: 45%;
            max-width: 128px; }
        & > *:last-child {
            max-width: 78px;
            width: 35%; }

        input.form-control,
        .FormSelectOptions__item {
            font-weight: $fw-normal !important; } }

    &__dates {
        .days {
            font-weight: $fw-normal;
            text-transform: uppercase;
            color: $c-dark-lg; } }

    &__row {
        display: flex; }


    @include media-breakpoint-up(lg) {
        &__selects {
            margin-bottom: $val-sm-2; }

        &__dates {
            .title {
                display: none; }
            .days {
                font-size: $fz-xs;
                margin-bottom: $val-md-3; }
            .dates {
                @include selected-style($date-fz-lg, $selected-padding-lg, $date-width-lg, $date-margin-lg); } }

        &__row {
            &:not(:last-child) {
                margin-bottom: $row-margin-lg; }
            & > *:not(:last-child) {
                margin-right: $date-margin-lg; }
            & > * {
                width: $date-width-lg;
                height: $date-width-lg;
                line-height: $date-width-lg;
                text-align: center; } } }

    @include media-breakpoint-down(md) {
        // max-width: 560px

        &__selects {
            margin-top: $val-xl-3;
            margin-bottom: $val-lg;

            .form-group input.form-control,
            .FormSelectOptions__item {
                font-size: $fz-md; }
            .spp-svg {
                bottom: 8px;
                svg {
                    height: 8px !important; } } }

        &__dates {
            @include box-shadow(0 0 16px rgba($c-forth, $o-mr));
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -$val-md-1;
            margin-right: -$val-md-1;
            padding: $val-lg-1 $val-md-1 $val-xl-1;
            border-radius: $br-xl-1 $br-xl-1 0 0;

            .title {
                font-size: $fz-lg;
                font-weight: $fw-bold;
                color: $c-dark-lg;
                margin-bottom: $val-sm;
                text-transform: none; }

            .days {
                font-size: $fz-md;
                margin-bottom: $val-lg-1; }
            .dates {
                @include selected-style($date-fz-md, $selected-padding-md, $date-width-md, $date-margin-md); } }

        &__row {
            &:not(:last-child) {
                margin-bottom: $row-margin-md; }
            & > *:not(:last-child) {
                margin-right: $date-margin-md; }
            & > * {
                width: $date-width-md;
                height: $date-width-md;
                line-height: $date-width-md;
                text-align: center; } } }

    @include media-breakpoint-down(sm) {

        &__dates {
            padding-bottom: $val-xl-2;
            .days {
                font-size: $fz-md;
                margin-bottom: $val-lg-1; }
            .dates {
                @include selected-style($date-fz-sm, $selected-padding-sm, $date-width-sm, $date-margin-sm); } }

        &__row {
            &:not(:last-child) {
                margin-bottom: $row-margin-sm; }
            & > *:not(:last-child) {
                margin-right: $date-margin-sm; }
            & > * {
                width: $date-width-sm;
                height: $date-width-sm;
                line-height: $date-width-sm;
                text-align: center; } } }


    @include media-breakpoint-down(md) {
        &__title {
            display: none; } } }
