.ElementsGroup {
    display: flex;
    align-items: center;

    &_center {
        justify-content: space-between; }

    &_left {
        justify-content: flex-start; }

    &_right {
        justify-content: flex-end; } }

@each $size, $value in $spacers {
    .ElementsGroup_left.ElementsGroup_#{$size} > *:not(:last-child) {
        margin-right: $value; }

    .ElementsGroup_right.ElementsGroup_#{$size} > *:not(:first-child) {
        margin-left: $value; } }
