.AdvertWhat {

    @include media-breakpoint-up(lg) {
        &.search-active {
            .CollapsibleLine,
            .CollapsibleVideoGroup {
 } } }                // display: none

    @include media-breakpoint-up(mg) {
        &.expanded {
            // &:not(.search-active)
            .CollapsibleVideo {
                max-width: 550px; }
            .CollapsibleVideoGroup {
                min-width: 460px; }
            .CollapsibleLine {
                min-width: 360px; } } }

    @media (min-width: 1500px) {
        &.expanded {
            display: flex;

            // &:not(.search-active)
            .CollapsibleVideo {
                max-width: 550px; }

            .Collapsible {
                width: 100%;
                &:not(:last-child) {
                    margin-right: $val-md-3; }

                .Collapsible__desktop .toggle-active {
                    display: none !important; } } } } }
