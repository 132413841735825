.SettingsPresets {
    position: relative;

    .FormSelect {
        .form-group input.form-control {
            margin-right: $val-md-2 !important;
            min-width: (170px - $val-md-2); } }

    .icon-close {
        position: absolute;
        top: 8px;
        right: 37px;
        z-index: 5;
        cursor: pointer;
        &:hover:not(:active) {
            opacity: $o-lg; } }

    .icon-edit {
        position: absolute;
        top: 8px;
        right: 19px;
        z-index: 5;
        cursor: pointer;
        &:hover:not(:active) {
            opacity: $o-lg; } } }
