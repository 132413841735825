@mixin divider {
    width: 100%;
    display: block;
    height: $bw-xs;
    border-radius: 500px;
    background-color: $c-dark-xs; }

@mixin divider-bb {
    border-bottom: $bw-xs solid $c-dark-xs; }

@mixin divider-bt {
    border-top: $bw-xs solid $c-dark-xs; }
