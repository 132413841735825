// Шрифты
$ff-title: 'Rubik', -apple-system, 'Circe', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$ff-text: $ff-title;
$lh-title: 1.3;
$lh-text: 1.3;

// Прозрачность
$o-mg: .8;
$o-xl: .72;
$o-lg: .64;
$o-md: .48;
$o-sm: .32;
$o-xs: .24;
$o-mr: .08;

$opacity-levels: (mg: $o-mg, xl: $o-xl, lg: $o-lg, md: $o-md, sm: $o-sm, xs: $o-xs, mr: $o-mr);

// Цвета
$c-prim: #ff3347;
$c-prim-lighter: rgba($c-prim, $o-mg);
$c-sec: #ff7700;
$c-third: #34d9c3;
$c-forth: #595980;

$c-dark: #0a0a0a;
$c-dark-lg: rgba($c-dark, $o-md);
$c-dark-md: rgba($c-dark, $o-sm);
$c-dark-sm: rgba($c-dark, $o-xs);
$c-dark-xs: rgba($c-dark, $o-mr);

$c-light: #ffffff;
$c-light-mg: #fff6ed;
$c-light-xl: #fffaf6;
$c-light-lg: #fff3e8;
$c-light-md: #f6f6f8;
$c-light-sm: #eeeef3;
$c-light-xs: #c4c4c4;

// Размеры шрифта
$fz-basic: 0.813rem; // 13px
$fz-mg: 1.750rem; // 28px
$fz-mg-1: 2.5rem; // 40px
$fz-xl: 1.5rem; // 24px
$fz-xl-1: 2rem; // 32px
$fz-lg: 1.125rem; // 18px
$fz-md: 1rem; // 16px
$fz-sm: 0.813rem; // 13px
$fz-xs: 0.688rem; // 11px

$fw-light: 300;
$fw-normal: 400;
$fw-bold: 500;

// Величины
$val-mg: 88px;
$val-mg-1: 80px;
$val-mg-2: 112px;
$val-mg-3: 93px;
$val-xl: 72px;
$val-xl-1: 64px;
$val-xl-2: 56px;
$val-xl-3: 50px;
$val-lg: 40px;
$val-lg-1: 48px;
$val-md: 30px;
$val-md-1: 32px;
$val-md-2: 36px;
$val-md-3: 28px;
$val-sm: 20px;
$val-sm-1: 16px;
$val-sm-2: 24px;
$val-sm-3: 18px;
$val-xs: 10px;
$val-xs-1: 12px;
$val-mr: 8px;
$val-mr-1: 2px;
$val-mr-2: 6px;

// z-index
$z-index-elements: 15;
$z-index-modal: 10;

// Границы
$bw-lg: 6px;
$bw-md: 4px;
$bw-sm: 2px;
$bw-xs: 1px;

// Тени
$bs-lg: 0 8px 20px;
$bs-md: 0 10px 16px;
$bs-sm: 0 4px 16px;
$bs-xs: 0 4px 4px;
$bs-mr: 0 2px 2px;

// Скругления
$br-mg: 500px;
$br-xl: 10px;
$br-xl-1: 32px;
$br-xl-2: 48px;
$br-lg: 6px;
$br-lg-1: 20px;
$br-lg-2: 8px;
$br-md: 4px;
$br-sm: 2px;
$br-xs: 1px;

// Брейкпоинты
$bp-mg: 1920px;
$bp-xl: 1200px;
$bp-lg: 992px;
$bp-md: 640px;
$bp-sm: 480px;
$bp-xs: 0;

// Контейнер
$container-mg: $bp-mg - ($val-lg*2);
$container-xl: $bp-xl - ($val-md*2);
$container-lg: $bp-lg - ($val-md*2);
$container-md: $bp-md - ($val-sm*2);
$container-sm: $bp-sm - ($val-xs*2);

$container-offset-mg: $val-lg;
$container-offset-xl: $val-md;
$container-offset-lg: $val-md;
$container-offset-md: $val-sm;
$container-offset-sm: $val-xs;
$container-offset-xs: $val-xs;

$container-offset-sizes: (mg: $container-offset-mg, xl: $container-offset-xl, lg: $container-offset-lg, md: $container-offset-md, sm: $container-offset-sm, xs: $container-offset-xs);
