.Navbar {
    position: relative;
    z-index: 1;
    flex-wrap: nowrap;
    padding: {
        top: $val-sm-1;
        bottom: $val-sm-1; }

    // Меню

    &__Menu {
        display: flex;
        align-items: center;
        .nav-link {
            line-height: 1; }
        a {
            font-family: $ff-title;
            font-size: $fz-sm;
            font-weight: $fw-bold;
            text-transform: uppercase;
            color: $c-dark !important;
            &:not(:last-child) {
                margin-right: $val-md; } }
        @include media-breakpoint-down(md) {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: $c-light;
            text-align: center;
            padding-bottom: $val-sm-1;
            a {
                margin: {
                    top: $val-sm-1;
                    bottom: $val-sm-1;
                    left: 0 !important;
                    right: 0 !important; } } } }

    // Иконка пользователя и выход

    &__User {
        .wrap {
            display: flex;
            justify-content: space-between;
            align-items: center; }
        .account {
            display: flex;
            align-items: center;
            img {
                height: $val-lg; }
            a {
                display: block;
                font-family: $ff-title;
                font-size: $fz-sm;
                font-weight: $fw-normal;
                color: $c-dark;
                text-decoration: none;
                span {
                    position: relative;
                    transform: translateY(2px); } } }
        .account-logo {
            position: relative;
            .account-logo-btn {
                display: none; }
            &:hover .account-logo-btn {
                display: block; }
            .close, .input-file {
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 10;
                //background-color: #474747
                svg {
                    height: 12px; } }
            .input-file {
                height: 50%;
                top: 0;
                cursor: pointer;
                opacity: 0.5;
                background-color: #41c700;
                text-align: center;
                &:hover {
                    opacity: 0.75; }
                svg {
                    height: 14px; } }
            .close {
                bottom: 0;
                background-color: red; } }


        .logout {
            img {
                height: $val-sm-1; } } }

    // Элементы в мобильной версии

    .ObjectsMenu {
        $t: ($val-sm+$val-mr);
        $xy: ($val-sm+($val-mr/2));
        $b: ($val-sm+($val-mr*2));
        padding-top: $val-sm-1/2;
        margin-bottom: -$val-sm-1;
        margin-top: -$val-sm-1;
        margin-left: -$xy;
        .nav {
            a {
                padding: $t $xy $b;
                white-space: nowrap;
                font-size: 1.25rem;
                @include media-breakpoint-down(sm) {
                    white-space: normal;
                    font-size: $fz-md; }
                &:first-child {
                    border-radius: 0 $br-md 0 0; }
                &:not(:hover):not(.active):not(:active) {
                    color: $c-dark; }
                &:hover:not(.active):not(:active) {
                    color: $c-dark-lg; } } } }

    // .NavbarCollapse
    &__SvgLogOut {
        margin-right: $val-sm;
        svg {
            margin-top: -3px; } }

    // Отступы

    @include media-breakpoint-up(lg) {
        //@include box-shadow(0 0 16px rgba($c-forth, .16))
        &__Menu {
            margin-right: $val-xl; }
        &__User .account {
            margin-right: $val-sm-1; }
        .account-logo {
            margin-right: $val-sm-1; }

        .NavbarCollapse,
        .ObjectsMenu {
            display: none; } }

    @include media-breakpoint-down(md) {
        &__Menu,
        &__User {
            display: none; }
        &__User {
            margin-right: $val-md;
            .account {
                margin-right: $val-sm-1;
                span {
                    display: none; } } }

        &:not(.without-menu) {
            .navbar-brand {
                display: none; } }

        &.without-menu {
            @include box-shadow(0 0 16px rgba($c-forth, .16));
            .ObjectsMenu {
                display: none; } } } }
