@mixin AdvertReport-modile {
    &:not(.warning) {
        .warning {
            display: none; } }

    &__header {
        position: relative;

        .AdvertsReport__grid {
            padding: $val-md $val-xs-1;
            .song,
            .warning,
            .open {
                & > * {
                    margin-top: 0; } }
            .song,
            .warning {
                svg {
                    height: 26px; } }
            .name {
                .desktop {
                    display: none; }
                .mobile {
                    display: block; } } } } }

.AdvertReport {
    font-family: $ff-title;
    overflow: hidden;
    margin-top: 4px;

    // Состояния

    &_gray:not(.selected) {
        background-color: $c-light-md; }

    &_gray,
    &.selected {
        .Song__List .item:nth-child(2n+1) {
            background-color: $c-light; } }

    &:not(&_gray):not(.selected) {
        .Song__List .item:nth-child(2n+1) {
            background-color: $c-light-md; } }

    &_gray.name-fade:not(.selected) {
        .AdvertsReport__grid > .name {
            &::after {
                background: rgb(255,255,255);
                background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f6f6f8",GradientType=1); } } }
    &.name-fade:not(&_gray) {
        .AdvertsReport__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); } }
    &.name-fade.selected {
        .AdvertsReport__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff3e8",GradientType=1); } }
    &.name-fade.highlighted:not(.selected) {
        .AdvertsReport__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff6ed",GradientType=1); } }

    // Элементы
    &.playing &__header {
        .AdvertsReport__grid .song .icon-play {
            display: none !important; }
        .rewind {
            z-index: 3; } }

    &:not(.playing) &__header {
        .AdvertsReport__grid .song .icon-pause {
            display: none;
            z-index: 1; }
        .rewind {
            z-index: -1; } }
    &__header {
        position: relative;

        .AdvertsReport__grid {
            padding: 8px 12px;
            // padding: $val-xs $val-mr
            & > * {
                position: relative;
                z-index: 3; }

            .timestamp {
                display: flex;
                font-size: $fz-sm;
                color: $c-dark-lg;
                font-weight: $fw-normal; }

            .channel {
                z-index: 5;
                display: flex;
                font-size: 0.813rem;

                // justify-content: center
                & > * {
                    margin-top: -2px; } }
            .clip {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                font-weight: 500;
                position: relative;
                margin-bottom: 1px;
                h4 {
                    font-size: $fz-sm;
                    color: $c-dark;
                    font-weight: $fw-bold;
                    margin: 0; }
                .desktop {
                    display: block; }
                .mobile {
                    display: none; }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 30px; } }
            .song {
                position: relative;
                margin-bottom: 1px;
                font-weight: 500;
                h4 {
                    font-size: $fz-sm;
                    color: $c-dark;
                    font-weight: $fw-bold;
                    margin: 0; }
                .desktop {
                    display: block; }
                .mobile {
                    display: none; }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 30px; } }
            .song svg,
            .clip svg,
            .open svg {
                &:hover {
                    cursor: pointer;
                    &:not(:active) {
                        opacity: $o-xl; } } } }

        .slider-1 {
            $c-start: rgba(255,236,238,1);
            $c-end: rgba(255,197,203,1);
            position: absolute;
            transition: .2s;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: rgb(255,236,238);
            background: -moz-linear-gradient(90deg, $c-start 0%, $c-end 100%);
            background: -webkit-linear-gradient(90deg, $c-start 0%, $c-end 100%);
            background: linear-gradient(90deg, $c-start 0%, $c-end 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFECEE",endColorstr="#ffc5cb",GradientType=1); }
        .slider-2 {
            position: absolute;
            transition: .2s;
            top: 0;
            bottom: 0;
            z-index: 2;
            display: block;
            width: $bw-xs;
            background-color: $c-prim; }
        .rewind {
            position: absolute;
            z-index: 4;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background-color: transparent; }
 }        // Ползунок

    &__List {
        margin: $val-mr;
        margin-top: 0;
        margin-right: $val-xs-1 + 1px;

        .ScrollContainer__more {
            margin-top: $val-sm-1;
            padding-bottom: $val-lg - $val-mr; }

        .item {
            display: flex;
            align-items: center;
            padding: $val-mr $val-sm-1;

            .number,
            .from,
            .date,
            .plays {
                font-size: $fz-sm;
                font-weight: $fw-normal; }

            .number,
            .from,
            .plays {
                color: $c-dark-lg; }

            .date {
                color: $c-dark; }

            .number {
                width: 28px; }

            .from {
                margin-right: $val-mr;
                span {
                    display: none; } }

            .date {
                margin-right: $val-sm-1; }
            .plays {
                position: relative;
                margin-left: auto;
                margin-right: $val-sm-1;
                span {
                    display: none; }
                &::before {
                    $v: 6px;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -($val-mr+$v);
                    display: block;
                    width: $v;
                    height: $v;
                    transform: translateY(-50%);
                    background-color: $c-third;
                    border-radius: 500px; } } } }

    audio {
        display: none; }

    @include media-breakpoint-down(lg) {
        @include track-modile; } }
