.ObjectScheduleGroupSmartMediaList {

    // Элементы

    &__header {
        .id,
        .time,
        .advert,
        .plus-button {
            @include grids-titles; }

        .plus-button {
            display: flex;
            align-items: center;
            .title {
                margin-right: $val-mr; }
            .button {
                display: flex;
                & > *:first-child {
                    margin-right: $val-mr; } } }

        .buttons.far {
            text-align: right;
            min-width: 95px;
            & > * {
                display: inline-block; }
            & > *:not(:last-child) {
                margin-right: $val-mr; } } }

    &__item {
        padding-top: ($val-mr / 2);
        padding-bottom: ($val-mr / 2);

        .id,
        .time {
            font-family: $ff-title;
            font-weight: $fw-normal;
            color: $c-dark; }

        .advert {
            &::before {
                content: '';
                @include ads-circle; } }
        &:not(.without-ads) {
            .advert::before {
                background-color: transparent; } }

        &.without-ads {
            .time {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: -($val-mr + $val-mr);
                    top: 50%;
                    transform: translateY(-50%);
                    @include ads-circle; } } }

        .tracks {
            .track {
                .track-buttons {
                    cursor: pointer; }
                .track-title {
                    font-family: $ff-title;
                    font-weight: $fw-bold;
                    color: $c-dark;
                    overflow: hidden; }
                &.playing {
                    .play {
                        display: none; } }
                &:not(.playing) {
                    .pause {
                        display: none; } }
                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 0; } } } } }
