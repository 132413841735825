.AdvertWhere {

    @include media-breakpoint-up(lg) {

        // Развёрнутый вид

        &.expanded {
            display: flex;

            .PartObjects {
                width: calc(60% - #{$val-xl-1});
                margin-right: $val-xl-1; }
            .PartObjects + .Collapsible {
                margin-right: $val-sm-1; }
            .Collapsible:not(.PartObjects) {
                flex-grow: 1; }

            .Collapsible .Collapsible__desktop .toggle-active {
                display: none !important; }

            .ScrollContainer__wrap[style*="max-height"] {
                max-height: 400px !important; } }

        // Когда поиск активен

        &.search-active {
            .Collapsible.PartObjects {
                margin: 0;
                width: 100%;
                .Objects__header,
                .Objects__places {
                    & > *:last-child {
                        display: none; } } }

            .Collapsible:not(.PartObjects) {
                display: none; } } }

    @media (min-width: 1330px) {
        &.expanded {
            .Collapsible:not(.PartObjects) {
                .Collapsible__header {
                    margin-bottom: 64px; } } } }

    @media (min-width: 1550px) {
        &.expanded {
            .Collapsible:not(.PartObjects) {
                .Collapsible__header {
                    margin-bottom: 51px; } } } }

    @media (min-width: 1800px) {
        &.expanded {
            .Collapsible:not(.PartObjects) {
                .Collapsible__header {
                    margin-bottom: 79px; } } } }

    @include media-breakpoint-up(mg) {
        &.expanded {
            .Collapsible:not(.PartObjects) {
                .Collapsible__header {
                    margin-bottom: 41px; } } } } }
