.Panel {
    background-color: $c-forth;

    &.disabled {
        pointer-events: none; }

    @include media-breakpoint-down(md) {
        height: 72px !important;
        &.disabled {
            display: none; } }
    &__Place {
        .title {
            color: $c-light;
            font-size: $fz-mg;
            text-transform: uppercase;
            font-family: $ff-title;
            font-weight: $fw-light;
            line-height: 1; }

        &.right-extend {
            .ElementsGroup_left {
                display: none; }
            .ElementsGroup_right {
                width: 100%; } }

        &.disabled, &:disabled {
            .title {
                cursore: default;
                opacity: $o-xs; } } }

    .wrap {
        display: flex;
        justify-content: space-between;
        & > * {
            transition: .25s;
            width: (100% / 3); } }
    .wrap-content {
        display: flex;
        justify-content: space-between;
        // & > *
        //     transition: .25s
        //     width: (100% / 3)
        &> :first-child {
            transition: .25s;
            width: (100% / 3); }
        &> :last-child {
            transition: .25s;
            width: (100% / 3)+(100% / 3); } }

    @include media-breakpoint-up(lg) {
        &__Place {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 190px;
            padding: $val-sm $val-md-1;

            &:last-child {
                padding-right: 0; }
            &:first-child {
                padding-left: 0; }
            &:not(:last-child) {
                border-right: $bw-xs solid $c-light; }

            .title {
                display: inline-block;
                width: auto;
                padding-right: $val-sm; }
            .body {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                width: 100%; }

            // Состояния

            &.not-expanded {
                width: 15%;
                .ElementsGroup_right {
                    width: 100%; }
                .ElementsGroup_left,
                .ElementsGroup_right > *:not(.expand) {
                    display: none; } }

            &.expanded {
                width: 200%;
                .SvgExpand {
                    display: none; } }

            &:not(.expanded) .SvgCollapse {
                display: none; }

            &.not-expanded-content {
                width: 15%;
                .ElementsGroup_right {
                    width: 100%; }
                .ElementsGroup_left,
                .ElementsGroup_right > *:not(.expand) {
                    display: none; } }

            &.expanded-content {
                width: 200% !important;
                .SvgCollapse {
                    display: inline-block; }
                .SvgExpand {
                    display: none; } }
            &:not(.expanded-content) .SvgCollapse {
 } } }                // display: none
    @include media-breakpoint-down(md) {
        position: relative;
        height: ($val-xl * 2);
        background-color: #707095;

        .spp-container {
            margin: 0;
            padding: 0;
            width: 100%; }

        .expand {
            display: none; }

        &__Place {
            .title,
            .body {
                @include spp-container_p; }

            &:first-child .title {
                text-align: left;
                border-radius: 0 8px 0 0; }
            &:not(:first-child):not(:last-child) .title {
                text-align: center;
                border-radius: 8px 8px 0 0; }
            &:last-child .title {
                text-align: right;
                border-radius: 8px 0 0 0; }

            .title {
                display: none;
                height: $val-xl - $val-mr;
                margin-top: $val-mr;
                padding-top: $val-sm-1;
                color: rgba($c-light, $o-md); }

            .body {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: $val-xl;
                display: none;
                padding-top: $val-sm;
                padding-bottom: $val-sm;
                background-color: $c-forth; }

            &.active {
                .title {
                    color: $c-light;
                    background-color: $c-forth; }

                .body {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    justify-content: space-between; } } } } }
