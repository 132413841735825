.Uploader {
    position: relative;

    &__input {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        cursor: pointer; }

    @include media-breakpoint-up(lg) {
        &__label {
            @include lables-titles;
            margin-bottom: $val-xs-1; }

        &__trackName {
            padding: $val-xs $val-xs;
            background-color: $c-light-md;
            border: $bw-xs solid $c-light-sm;
            border-radius: $br-md;
            font-family: $ff-title;
            color: #ff3347;
            font-weight: $fw-bold;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: 1s show ease;
            @keyframes show {
                from {
                    opacity: 0; }
                to {
                    opacity: 1; } }
            span {
                width: 80%;
                text-align: center; } }

        &__drop {
            padding: $val-md $val-xs-1;
            background-color: $c-light-md;
            border: $bw-xs solid $c-light-sm;
            border-radius: $br-md;
            cursor: pointer;
            &.dragover {
                opacity: $o-lg; }
            &.blocked-by-type {
                cursor: no-drop; }

            font-family: $ff-title;
            color: $c-dark-lg;

            & > .title {
                font-size: $fz-sm;
                font-weight: $fw-bold; }
            & > .comment {
                font-size: $fz-xs;
                font-weight: $fw-normal; }

            display: flex;
            justify-content: space-around;
            align-items: center;
            & > * {
                max-width: 40%; }
            & > .title {
                display: flex;
                align-items: center;
                .spp-svg {
                    width: min-content;
                    margin-right: $val-xs-1; } } }

        &__or {
            font-family: $ff-title;
            font-weight: $fw-normal;
            font-size: $fz-xs;
            color: $c-dark-lg;
            text-align: center;
            margin-top: $val-xs-1;
            margin-bottom: $val-xs-1; }

        &__button {
            display: flex;
            justify-content: center; } }

    @include media-breakpoint-down(md) {
        &__label,
        &__drop,
        &__or {
            display: none; }

        &__button {
            & > * {
                width: 100%; } } } }
