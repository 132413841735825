.mobile-modal {
    &:not(&_disabled) {

        .mobile-modal__buttons {
            display: flex;
            border-top: $bw-xs solid $c-light-sm;
            margin-bottom: -$val-mr;
            margin-left: -$val-xs-1;
            margin-right: -$val-xs-1;
            & > * {
                width: 100%;
                text-align: center;
                font-family: $ff-title;
                font-size: $fz-sm;
                font-weight: $fw-bold;
                color: $c-dark;
                cursor: pointer;
                padding: $val-sm-1 0;
                &:not(:last-child) {
                    border-right: $bw-xs solid $c-light-sm; } }

            .cancel {
                color: $c-dark-lg; } }

        @include media-breakpoint-down(md) {
            transition: .1s;
            position: fixed;
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: auto;
            width: 100% !important;
            max-width: 10000px !important;
            padding: $val-sm-2 !important;
            border-radius: 0 !important;
            border: 0 !important;
            background-color: rgba($c-dark, $o-lg);
            &:not(.opened) {
                transform: scale(.98);
                opacity: 0;
                visibility: hidden;
                filter: blur(2px); }

            .mobile-modal__title {
                display: inline-block;
                font-size: $ff-title;
                font-weight: $fw-light;
                font-size: $fz-mg;
                color: $c-dark-lg;
                text-transform: uppercase; }

            .mobile-modal__content {
                width: 100%;
                padding: $val-lg $val-md-1;
                border-radius: $br-md;
                border: $bw-xs solid $c-light-lg;
                background-color: $c-light; }

            .mobile-modal__buttons {
                margin-bottom: -$val-lg !important;
                margin-left: -$val-md-1 !important;
                margin-right: -$val-md-1 !important;
                & > * {
                    color: $c-dark !important;
                    font-size: $fz-xl !important;
                    padding-top: $val-md-1;
                    padding-bottom: $val-md-1; } } } } }
