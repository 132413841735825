.ObjectSilentGroupSilentList {

    &__item {
        display: flex;
        justify-content: space-between;
        font-family: $ff-title;
        font-size: $fz-sm;
        color: $c-dark;
        width: 100%;

        .date {
            font-weight: $fw-normal; }

        .time {
            //flex-grow: 1
            text-align: center;
            //font-weight: $fw-bold
            max-width: 300px;
 }            //margin-right: auto

        @include media-mobile {
            flex-grow: 1;
            align-items: center;

            .date {
                margin-right: $val-sm; } } } }
