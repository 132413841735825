@mixin set-svg-color($variant) {
    svg, svg path, svg polygon {
        fill: map-get($theme-colors, $variant) !important; } }

@mixin set-svg-size($size) {
    display: inline-block;
    svg {
        position: relative;
        height: map-get($spacers, $size); } }

@mixin set-svg-size-auto {
    svg {
        height: 1em; } }
