@import 'Alert/import';
@import 'Button/import';
@import 'ButtonCircle/import';
@import 'ButtonWhite/import';
@import 'CollapsibleCard/import';
@import 'CollapsiblePlaylist/import';
@import 'ElementsGroup/import';
@import 'Form/import';
@import 'Lines/import';
@import 'List/import';
@import 'MobileControls/import';
@import 'MobileModal/import';
@import 'Modal/import';
@import 'Navbar/import';
@import 'NavbarCollapse/import';
@import 'Objects/import';
@import 'ObjectsByTags/import';
@import 'ObjectsGroup/import';
@import 'ObjectsMenu/import';
@import 'Panel/import';
@import 'ScrollContainer/import';
@import 'Search/import';
@import 'Settings/import';
@import 'SlideMenu/import';
@import 'Space/import';
@import 'Spinner/import';
@import 'StringDays/import';
@import 'SVG/import';
@import 'Tags/import';
@import 'Tracks/import';
@import 'Uploader/import';
@import 'ContentPlaylists/import';
@import 'Songs/import';
@import 'ContentVideolists/import';
@import 'Footer/import';
@import 'Login/import';
@import 'ObjectsState/import';
@import "inputFile";

.App--is-loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  &::before {
    content: '';
    z-index: 1000;
    background: #2f2f2f4a;
    position: absolute;
    width: 100%;
    display: block;
    min-height: 100%; }

  .App-Spinner {
    position: fixed;
    z-index: 10000;
    top: 50vh;
    left: 50vw;
    & > .scale {
      transform: scale(1.5); } } }
