.ModalCreateLine {

    &__grid {
        display: flex;
        justify-content: space-between;
        align-items: start; }

    &__Editor {
        label {
            font-family: $ff-title;
            font-size: $fz-sm;
            font-weight: $fw-normal;
            color: $c-dark-lg;
            text-transform: uppercase;
            margin-bottom: $val-md-1; }

        div.DraftEditor-root {
            border-bottom: $bw-xs solid $c-dark;
            height: 150px;
            overflow-y: auto; }
        div.DraftEditor-editorContainer,
        div.public-DraftEditor-content {
            height: 100%; } }

    .line-offset {
        margin-bottom: $val-md-1; }


    @include media-breakpoint-up(lg) {
        &__grid {
            & > *:not(:last-child) {
                margin-right: $val-md-1; }

            .font {
                flex: 2 2; }

            .font-size {
                flex: 1 1; }

            .font-color {
                flex: 1 1; } } }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        & > * {
            width: 100%; }

        .Modal__header {
            order: -1; }
        .templates {
            order: -1;
            margin-bottom: $val-md-1;
            padding-bottom: $val-md-1;
            border-bottom: $bw-xs solid $c-light-sm; }
        &__Editor {
            margin-bottom: 0; }

        &__grid {
            .font {
                width: 100%;
                margin-bottom: $val-md-1; }
            .font-size {
                margin-right: 10%; }
            .font-size,
            .font-color {
                width: (100% - 10%) / 2; } }

        &__Editor {
            div.DraftEditor-root {
                border-bottom: 0;
                height: 100px; } } } }
