@mixin setsmob {
    .Settings {

        .setsmob-hidden {
            display: none !important; }

        .setsmob {

            // flex

            &-fd-column-reverse {
                .SettingsGroup__items {
                    flex-direction: column-reverse; } }

            // col

            &-col-1 {
                width: 100%;
                margin: {
                    right: 0;
                    left: 0; }
                .SettingsGroup__items {
                    flex-wrap: wrap !important;
                    margin: {
                        right: 0 !important;
                        left: 0 !important; }
                    & > * {
                        width: 100% !important;
                        margin: {
                            right: 0 !important;
                            left: 0 !important; }
                        &:not(:last-child) {
                            margin-bottom: $val-md-1; } } } }

            // p-m

            &-pb-lg1 {
                padding-bottom: $val-lg-1; } } } }

@mixin setsex {
    .Settings {

        .setsmob-visible,
        .setssimp-visible {
            display: none !important; }

        .setsex {

            // flex

            &-flex-wrap {
                display: flex;
                flex-wrap: wrap; }

            &-flex-nowrap {
                display: flex;
                flex-wrap: nowrap; }

            &-align-end {
                align-items: flex-end; }

            &-align-start {
                align-items: flex-start; }

            // col

            &-col-1 {
                width: 100%;
                margin: {
                    right: 0;
                    left: 0; } }

            &-col-2:not(.setsex-col-2-nowrap) {
                width: 48%; }

            &-col-2-nowrap {
                display: flex;
                width: 100%;
                & > * {
                    width: 48%; } }

            &-col-4 {
                width: 22%; }

            &-col-4-nowrap {
                display: flex;
                width: 100%;
                & > * {
                    width: 22%; } }

            &-mr {
                margin-right: 4%; }
            &-ml {
                margin-left: 4%; }

            // divider

            &-no-divider {
                padding-bottom: 0;
                border-bottom: 0; }

            &-divider-pb {
                padding-bottom: $val-md-1 !important; }

            &-divider-bb {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 208%;
                    height: $bw-xs;
                    background-color: $c-dark-xs; } }

            // mw

            &-mw-items-200 .SettingsGroup__items > * {
                max-width: 200px; }

            &-mw-items-300 .SettingsGroup__items > * {
 }                //max-width: 300px

            // p-m

            &-pt-0 {
                padding-top: 0; }
            &-pb-0 {
                padding-bottom: 0; }
            &-mt-0 {
                margin-top: 0; }
            &-mb-0 {
                margin-bottom: 0; }
            &-pt-items-0 .SettingsGroup__items > * {
                padding-top: 0; }
            &-pb-items-0 .SettingsGroup__items > * {
                padding-bottom: 0; }
            &-mt-items-0 .SettingsGroup__items > * {
                margin-top: 0; }
            &-mb-items-0 .SettingsGroup__items > * {
                margin-bottom: 0; }

            // order

            &-o-1 {
                order: 1; }
            &-o-2 {
                order: 2; }
            &-o-3 {
                order: 3; }
            &-o-4 {
                order: 4; }
            &-o-5 {
                order: 5; }
            &-o-6 {
                order: 6; }
            &-o-7 {
                order: 7; }
            &-o-8 {
                order: 8; }
            &-o-9 {
                order: 9; }
            &-o-10 {
                order: 10; }
            &-o-11 {
                order: 11; }
            &-o-12 {
                order: 12; }
            &-o-13 {
                order: 13; } } } }

@mixin setssimp {
    .Settings {

        .setsmob-visible,
        .setsex-visible {
            display: none !important; } } }


@include place-expanded-desktop {
    @include setsex; }
@include place-expanded {
    @include setssimp; }
@include place-not-expanded {
    @include setssimp; }

@include media-mobile {
    @include setsmob; }
