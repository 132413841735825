.Modal {
    // @include spp-scroll
    transition: .1s;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-modal;
    padding: $val-mg-1 $val-sm;
    background-color: rgba($c-dark, $o-lg);
    &:not(.opened) {
        transform: scale(.98);
        opacity: 0;
        visibility: hidden;
        filter: blur(2px); }

    &__header {
        position: relative;
        h2 {
            display: inline-block;
            font-size: $ff-title;
            font-weight: $fw-light;
            font-size: $fz-mg;
            color: $c-dark-lg;
            text-transform: uppercase;
            margin-right: $val-md; }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            cursor: pointer;
            &:hover {
                opacity: $o-md; } }

        &:not(&_m-0) {
            margin-bottom: $val-xl-2; } }

    &__addedTitleElement {
        display: inline; }

    &__content {
        height: max-content;
        padding-top: $val-xl-2;
        padding-bottom: $val-xl-2;
        border-radius: $br-md;
        border: $bw-xs solid $c-light-lg;
        background-color: $c-light; }

    &_sm &__content {
        width: 700px;
        max-width: 700px;
        padding-left: $val-xl-1;
        padding-right: $val-xl-1; }
    &_md &__content {
        width: 1015px;
        max-width: 1015px;
        padding-left: $val-xl-1;
        padding-right: $val-xl-1; }
    &_lg &__content {
        width: 1300px;
        max-width: 1300px;
        padding-left: $val-lg + 4px;
        padding-right: $val-lg + 4px; }

    &__buttons {
        font-family: $ff-title;
        font-weight: $fw-bold;
        color: $c-dark;
        cursor: pointer;
        text-align: center;
        display: flex;
        border-top: $bw-sm solid $c-light-sm;
        & > * {
            width: 100%;
            &:hover:not(:active) {
                opacity: $o-mg; }
            &:not(:last-child) {
                border-right: $bw-sm solid $c-light-sm; } } }

    @include media-breakpoint-up(lg) {
        &__buttons {
            margin-top: $val-xl-2;
            margin-bottom: -$val-xl-2;
            font-size: $fz-sm;
            & > * {
                padding: $val-sm $val-mr-2; } }

        &_sm &__buttons, &_md &__buttons {
            margin-right: -$val-xl-1;
            margin-left: -$val-xl-1; }
        &_lg &__buttons {
            margin-right: -$val-lg;
            margin-left: -$val-lg; }

        &__footer {
            margin-top: $val-mg-1; } }

    @include media-breakpoint-down(md) {
        padding: $val-sm-2;

        &__content {
            padding: $val-lg $val-md-1 !important;
            width: 100% !important;
            max-width: 10000px !important; }

        &__buttons {
            margin: $val-lg (-$val-md-1) (-$val-lg);
            font-size: $fz-xl;
            & > * {
                padding: $val-md-1 $val-mr-2; } }

        &__footer {
            margin-top: $val-xl-2;
            & > .btn {
                width: 100%; } } } }
