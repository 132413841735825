.Modal_type_video {
    .Modal__content {
        padding: 0 !important;
        background-color: transparent;
        border: 0; } }

.ModalVideo {

    &__wrap {

        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: $c-dark;
        &.playing {
            background-image: none !important; }

        video {
            width: 100%;
            outline: none;
            margin-bottom: -4px; } }

    &__header {
        transition: .3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        justify-content: space-between;

        .title {
            font-family: $ff-title;
            font-weight: $fw-bold;
            color: $c-light; }

        .close {
            width: $val-md-1;
            .spp-svg {
                height: $val-md-1;
                width: $val-md-1;
                border-radius: 500px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    height: $val-sm-3 - 1; } } } }

    @include media-breakpoint-up(lg) {
        &__wrap {
            position: relative;
            &.playing:not(:hover) {
                .ModalVideo__header {
                    transition-delay: 1.4s;
                    transform: translateY(-30px);
                    opacity: 0; } }
            video {
                height: 600px; } }

        &__header {
            padding: $val-md-1 $val-xl-1;
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0.01) 0%, rgba(10,10,10,0.9) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.01) 0%, rgba(10,10,10,0.9) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,0.01) 0%, rgba(10,10,10,0.9) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#0a0a0a",GradientType=1);
            .title {
                font-size: $fz-xl; }
            .close {
                .spp-svg {
                    background-color: $c-dark-lg; } } } }

    @include media-breakpoint-down(md) {
        &__wrap {
            video {
                height: 340px; } }

        &__header {
            padding: $val-sm-2;
            .title {
                font-size: $fz-md; } } } }

@include media-breakpoint-down(md) {
    .Modal_type_video {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }



.ModalVideoEdit {

    .ModalDoubleGrid {
        gap: 135px; }

    input {
        min-width: 300px; }

    .description {
        width: 450px !important; }

    @include media-breakpoint-up(lg) {
        .ModalAdsDoubleGrid__left {
            & > *:not(.ModalAdsDoubleGrid__title):not(:last-child) {
                margin-bottom: $val-md-1; } }

        .FormInputText__comment {
            display: none; }

        .Uploader {
            margin-top: $val-lg-1; } }

    @include media-breakpoint-down(md) {
        .ModalAdsDoubleGrid {
            display: flex;
            flex-direction: column-reverse;
            .ModalAdsDoubleGrid__right {
                margin-bottom: $val-mg-1; }
            .ModalAdsDoubleGrid__left {
                margin-bottom: 0; } }

        .ModalAdsDoubleGrid__left {
            & > *:not(.ModalAdsDoubleGrid__title):not(:last-child) {
                margin-bottom: $val-md-1; } }

        .ModalAdsDoubleGrid__right {
            .Uploader {
                margin-bottom: $val-mg-1; } } } }
