@mixin alone {
    .Objects__header {
        display: none; }

    .Objects__list {
        @include box-shadow($bs-md rgba($c-forth, $o-mr));
        background-color: $c-light; }

    .ObjectsLeft {
        margin-bottom: $val-xl-1; }

    .Objects__container-main {
        &.ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap {
            overflow-y: auto;
            padding-right: 0; }
        &.ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content > * {
            display: block; }
        &.ScrollContainer > .ScrollContainer__more {
            display: none; } }

    .Objects__container-list {
        &.ScrollContainer > .ScrollContainer__more {
            padding-bottom: $val-lg; } } }

@mixin double {
    .Objects__place .Objects__title {
        display: none; }

    .Objects__places {
        display: flex;
        & > * {
            width: 50%;
            flex-grow: 1; } }

    .Objects__container-main {
        &.ScrollContainer > .ScrollContainer__background::after {
            content: ''; } }

    .Objects__container-list {
        &.ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap {
            overflow-y: auto;
            padding-right: 0;
            max-height: 67vh; }
        &.ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content > * {
            display: block; }
        &.ScrollContainer > .ScrollContainer__more {
            display: none; } } }

.Objects {
    .ScrollContainer__wrap {
        max-height: 62vh !important; }
    &__Empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark-lg;
        margin: $val-xs; }

    &__header {
        @include Collapsible-child-control-pb;
        display: flex;
        padding-right: $val-xs;
        & > * {
            width: 100%; } }
    &__header &__title {
        margin-bottom: 0; }

    &__title {
        font-family: $ff-title;
        font-weight: $fw-normal;
        text-transform: uppercase;
        color: $c-dark-lg; }

    &__message {
        font-family: $ff-title;
        font-weight: $fw-normal;
        color: rgba($c-dark, $o-xl);
        line-height: 26px; // $lh-title
        margin-left: $bw-md;
        margin-right: $bw-md; }

    &__item {
        font-family: $ff-title;
        font-weight: $fw-bold;
        cursor: pointer;
        & > span > span:first-child {
            //font-weight: 400
            //display: inline-block
 }            //width: 41px
        &:not(.selected) {
            color: $c-dark;
            &:nth-child(2n) {
                background-color: $c-light-md; } } }

    @include media-breakpoint-up(lg) {
        @include double;
        &__list {
            border-radius: $br-md; }
        &__title {
            font-size: $fz-xs;
            padding-left: $val-xs-1 + $bw-md;
            margin-bottom: $val-xs-1; }
        &__message {
            font-size: $fz-lg; }
        &__item {
            font-size: $fz-sm;
            margin-top: $bw-sm;
            margin-bottom: $bw-sm;
            border: $bw-sm solid transparent; }
        &__item,
        &__message {
            padding: $val-mr $val-xs-1; } }

    @include media-breakpoint-down(md) {
        @include alone;
        &__list {
            border-radius: $br-lg-2; }
        &__title {
            font-size: $fz-md;
            padding-left: $val-sm-1 + $bw-md;
            margin-bottom: $val-sm-2; }
        &__message {
            font-size: $fz-xl; }
        &__item {
            font-size: $fz-lg; }
        &__item,
        &__message {
            padding: $val-sm-2 $val-sm-1; } } }
