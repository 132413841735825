.SettingsTabs {

    &__wrap {
        display: flex;
        & > * {
            width: 100%; } }

    &__tab {
        font-family: $ff-title;
        font-weight: $fw-bold;
        border-bottom: $bw-xs solid transparent;
        text-align: center;
        cursor: pointer;
        &:not(.active) {
            color: $c-dark-lg;
            border-bottom-color: $c-dark-xs; }
        &.active:not(.disabled) {
            color: $c-prim;
            border-bottom-color: $c-prim; }
        &.disabled {
            color: $c-dark-sm;
            border-bottom-color: $c-dark-xs;
            cursor: default; }

        @include media-tablet-and-up {
            padding: $val-mr; }

        @include media-mobile {
            padding: $val-sm-1; } } }

