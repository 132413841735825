.ObjectsState__grid {
    display: flex;
    align-items: center;
    // height: 35px
    @include media-breakpoint-down(sm) {
        height: 70px; }

    .id {
        width: 100%;
        max-width: 36px;
        white-space: nowrap;
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }
    .state {
        width: 100%;
        max-width: 60px;
        white-space: nowrap;
        text-align: center;
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }

    .name {
        text-align: left;
        width: 100%;
        overflow: hidden;
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }
    .sync_time {
        width: 100%;

        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }
    .sync_start_time {
        width: 100%;
        text-align: left;
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }
    .ip {
        width: 100%;
        // text-align: center
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }
    .error {
        width: 100%;
        font-weight: 500;
        @include media-breakpoint-down(xl) {
            button span {
                font-size: 0.7rem; } } }

    & > *:not(:last-child) {
        margin-right: 11px; } }
