.ObjectScheduleGroupSmart {

    &__header {
        margin-bottom: $val-lg;
        .SettingsTabs {
            margin-bottom: $val-md-1; } }

    .active-tab-0 {
        .tab-1 {
            display: none !important; } }

    .active-tab-1 {
        .tab-0 {
            display: none !important; } } }

