.FormSelect {

    .form-group input.form-control {
        cursor: pointer;
        padding-right: $val-sm !important; }

    &__content {
        @include FormSelect__content;

        .ScrollContainer__wrap::-webkit-scrollbar-track-piece {
            background: $c-light-md; }

        @include media-tablet-and-up {
            min-width: 170px; }

        @include media-mobile {
            min-width: 120px; } }

    select {
        display: none !important; } }
