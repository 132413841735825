.ModalAdsCreate {

    .c-prim {
        color: $c-prim;
        font-weight: $fw-bold; }

    @include media-breakpoint-up(lg) {
        &__grid {
            display: flex;
            flex-wrap: wrap;
            margin-right: -$val-xl-1;
            margin-bottom: -$val-md-1;
            & > * {
                width: calc(50% - #{$val-xl-1});
                margin-right: $val-xl-1;
                margin-bottom: $val-md-1; } }

        .Uploader-title {
            display: none; }
        .Uploader {
            margin-bottom: $val-lg-1; }

        .FormInputText__comment {
            display: none; } }

    @include media-breakpoint-down(md) {
        .ModalAdsDoubleGrid__left {
            display: flex;
            flex-wrap: wrap;
            & > * {
                width: 100%; }

            .ModalAdsDoubleGrid__title.Uploader-title {
                order: 1; }
            .Uploader {
                order: 2; }
            .ModalAdsDoubleGrid__title:not(.Uploader-title) {
                order: 3; }
            .ModalAdsCreate__grid {
                order: 4; } }

        &__grid {
            & > *:not(:last-child) {
                margin-bottom: $val-xl-1; } }

        .Uploader {
            margin-bottom: $val-mg-1; } } }
