
// Заголовки

@mixin spp-font-title-1 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 300;
    font-size: $fz-mg;
    color: $c-dark;
    text-transform: uppercase; }

@mixin spp-font-title-2 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 500;
    font-size: $fz-xl;
    color: $c-dark;
    text-transform: uppercase; }

@mixin spp-font-title-3 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 500;
    font-size: $fz-md;
    color: $c-dark-lg;
    text-transform: uppercase; }

@mixin spp-font-title-4 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 400;
    font-size: $fz-md;
    color: $c-dark;
    text-transform: uppercase; }

@mixin spp-font-title-5 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 500;
    font-size: $fz-md;
    color: $c-dark;
    text-transform: none; }

@mixin spp-font-title-6 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 400;
    font-size: $fz-xs;
    color: $c-dark-lg;
    text-transform: uppercase; }

@mixin spp-font-title-7 {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 500;
    font-size: $fz-sm;
    color: $c-dark-lg;
    text-transform: none; }


// Простой текст

@mixin spp-font-text-lg {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 400;
    font-size: $fz-lg;
    color: $c-dark-lg;
    text-transform: none; }

@mixin spp-font-text-md {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 500;
    font-size: $fz-sm;
    color: $c-dark;
    text-transform: none; }

@mixin spp-font-text-sm {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 400;
    font-size: $fz-xs;
    color: $c-dark-md;
    text-transform: none; }

//
// Стили
//

.spp-font-title-1 {
    @include spp-font-title-1; }

.spp-font-title-2 {
    @include spp-font-title-2; }

.spp-font-title-3 {
    @include spp-font-title-3; }

.spp-font-title-4 {
    @include spp-font-title-4; }

.spp-font-title-5 {
    @include spp-font-title-5; }

.spp-font-title-6 {
    @include spp-font-title-6; }

.spp-font-title-7 {
    @include spp-font-title-7; }


// Простой текст

.spp-font-text-lg {
    @include spp-font-text-lg; }

.spp-font-text-md {
    @include spp-font-text-md; }

.spp-font-text-sm {
    @include spp-font-text-sm; }
