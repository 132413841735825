.FormSelectColorpicker {

    .form-control {
        text-transform: uppercase; }

    .color-preview {
        z-index: 1;
        width: $val-sm-1;
        height: $val-sm-1;
        border-radius: $br-sm; }

    input[type="color"] {
        visibility: hidden;
        z-index: 100; }

    .color-preview,
    input[type="color"] {
        position: absolute;
        left: 0;
        bottom: 8px; } }

.FormSelect.FormSelectColorpicker input.form-control {
    padding-left: ($val-sm-1 + $val-mr) !important; }
