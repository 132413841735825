.Settings {

    &__Menu {
        .title {
            margin: 0 0 ($val-md-1 * 2); }
        .link {
            @include spp-font-title-2;
            display: block;
            text-decoration: none;
            &:not(:last-child) {
                margin-bottom: $val-md-1; } } }

    @include media-breakpoint-up(lg) {
        .Space__Place {
            padding-top: $val-md-2; } }

    @include media-breakpoint-down(md) {
        .Space__Place {
            padding-top: $val-mg-2; }

        &__Menu {
            .title {
                font-size: $fz-xl-1; }
            .link {
                font-size: $fz-mg-1;
                &:not(:last-child) {
                    margin-bottom: $val-lg; } } } } }
