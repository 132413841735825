.Search {
    &__wrap {
        position: relative; }

    .form-group {
        display: flex;
        margin-bottom: 0;

        .SvgClose {
            margin-left: $val-sm-1; } }

    &.active, &:focus, {
        width: 100%;
        .Search__wrap {
            width: 100%; }

        input + .spp-svg {
            display: none !important; }

        .ButtonCircle:not(.active):not(:active) {
            background-color: $c-light; } }

    &:not(.active):not(:focus) {
        .SvgClose {
            display: none; } }

    @include media-breakpoint-up(mg) {
        @include search-size-normal; }

    @include media-breakpoint-down(xl) {
        @include search-size-compact; } }
