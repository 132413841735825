.ObjectsGroup {
    @include Collapsible-child-content-offset;

    &__item {
        font-family: $ff-title;
        font-weight: $fw-bold;
        cursor: pointer;
        &:not(.select) {
            color: $c-dark; }
        &:nth-child(2n):not(.selected) {
            background-color: $c-light-md; } }

    .CollapsibleCard {
        &__title {
            span {
                font-weight: $fw-normal; } } }

    @include media-breakpoint-up(lg) {
        &__item {
            font-size: $fz-sm;
            padding: $val-mr $val-sm-1; }

        .CollapsibleCard {
            &:not(.selected):not(.opened) .CollapsibleCard__title {
                color: $c-dark;
                span {
                    color: $c-dark-lg; } }
            &.opened:not(.selected) .CollapsibleCard__title {
                color: $c-dark-lg; } } }

    @include media-breakpoint-down(md) {
        &__item {
            font-size: $fz-lg;
            padding: $val-sm-1 $val-sm-2; }

        .CollapsibleCard {
            &:not(.selected):not(.opened) .CollapsibleCard__title {
                color: rgba($c-dark, $o-lg);
                span {
                    color: $c-dark-lg; } }
            &.opened:not(.selected) .CollapsibleCard__title {
                color: $c-dark-lg; } } } }
