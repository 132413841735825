.ModalCastinfo {

    &__content {
        & > *:not(:last-child) {
            margin-bottom: $val-sm-2; } }

    @include media-breakpoint-down(md) {
        &__content {
            & > *:not(:last-child) {
                margin-bottom: $val-xl-2; }

            .FormDatepicker .placeholder,
            .form-check label {
                font-size: $fz-lg; } } } }
