.sp-top {
    padding-top: 36px !important; }
// .Report_List
//     max-height: calc(100vh - 300px)
//  // overflow-y: scroll
//     height: 80%
// .ObjectWhat
//     display: flex
//     flex-basis: auto
//     // padding: 0
//     flex-grow: 1
//     bottom: 20px
//     > *
//         width: 100%

// .ObjectsWhere

.Object-report {
    display: flex;
    flex-direction: column;
    .ScrollContainer__wrap {
        max-height: 49vh !important; }
    &_menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        .btn {
            min-width: 1px;

            i {
                @include media-breakpoint-down(sm) {
                    display: none !important; } } }
        // button:hover
        //     color: #ff3347
        .Back {
            .spp-svg {
                margin-right: 0; } }
        &-control-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
            background-color: #ffffff;
            border-radius: 4px;
            width: 100px;
            min-width: 30px !important;
            padding-bottom: 6px;
            padding-top: 6px;
            padding-left: 8px;
            padding-right: 8px;
            font-weight: 500;
            margin-right: 0;
            font-size: 1rem;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
                justify-content: center; } }
        &-element {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
            background-color: #ffffff;
            border-radius: 4px;
            width: 100%;
            padding-bottom: 6px;
            padding-top: 6px;
            padding-left: 8px;
            padding-right: 8px;
            font-weight: 500;
            margin: auto;
            font-size: 1rem;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
                justify-content: center; } }
        .active-rep {
            color: #ff3347; } }
    &_controls {
        margin-top: 28px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            color: rgba(10, 10, 10, 0.48);
            font-weight: 500; }
        h4 {
            margin-top: 22px; }
        &-datepicker2 {
            margin-top: 20px;
            display: flex;
            flex-direction: row; }

        &-datepicker {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
            align-items: end;

            &-date {
                display: flex;
                flex-direction: column;
                gap: 21px;
                &-arrows {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    svg {
                        height: 10px!important; } } } } } }
