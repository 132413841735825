.PartObjects {

    .Collapsible__header {
        position: relative;
        z-index: 3; }
    .Collapsible__mobile {
        .elements {
            margin-bottom: -22px;
            justify-content: flex-end;
            padding-right: $val-sm; } }

    @include media-mobile {
        .Collapsible__header {
            .elements {
                padding-right: 0;

                .FormSelect {
                    width: 100%; } } }

        .Objects {
            .Objects__title {
                display: none; } } } }
