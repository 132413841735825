@mixin input-placeholder-color($color) {
    &::-moz-placeholder {
        color: $color; }
    &::-webkit-input-placeholder {
        color: $color; } }

@mixin input-value-color($color) {
    color: $color; }

@mixin form-disabled {
    pointer-events: none;
    opacity: $o-md; }
