.ModalAdsReport {

    &__grid {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-normal;
        color: $c-dark-lg;
        margin-top: $val-md; }

    .reports {
        .report__item {
            position: relative;
            font-size: $fz-sm;
            color: $c-dark;

            & > * {
                position: relative;
                z-index: 2; }
            &:not(.final):nth-child(odd)::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                background-color: $c-light-md; }

            .title {
                position: relative; }
            .title--bold {
                font-weight: $fw-bold; }

            .time {
                .timeDuration,
                .timeLeft {
                    display: inline-block; }
                .timeLeft {
                    position: relative;
                    &::before {
                        content: "/";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: $ff-title;
                        font-size: $fz-sm;
                        font-weight: $fw-normal;
                        color: $c-dark-lg; } } }

            &.final::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 1;
                display: block;
                height: $bw-xs;
                background-color: $c-light-sm; } } }

    &_inside &__report .outside {
        display: none; }
    &_outside &__report .inside {
        display: none; }

    @include media-breakpoint-up(lg) {
        .Modal__header {
            display: flex;
            h2 {
                margin-bottom: 0; }
            .Modal__addedTitleElement {
                display: inline-flex;
                align-items: center; } }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $val-md-1;
            .form-control {
                min-width: 230px; } }

        &__toggle-place {
            font-size: $fz-sm;
            font-weight: $fw-bold;
            color: $c-dark-lg;
            div {
                display: inline;
                cursor: pointer;
                &.active {
                    color: $c-dark; }
                &:first-child {
                    &::after {
                        content: " / ";
                        color: $c-dark-lg; } } } }

        &__grid {
            .start {
                min-width: 65px; }
            .title {
                width: 100%; }
            .time {
                min-width: 160px; }
            & > *:not(:last-child) {
                margin-right: $val-lg-1; } }

        .header {
            margin-bottom: $val-mr;
            .mob {
                display: none; } }

        .reports {
            .report__item {
                $times-width: 50px;
                position: relative;
                padding: $val-xs-1 0;

                &:not(.final):nth-child(odd)::before {
                    left: (65px + $val-lg-1) - $val-sm-2; }

                .time {
                    .timeDuration,
                    .timeLeft {
                        width: $times-width; }
                    .timeLeft {
                        &::before {
                            left: -10px; } } }

                &.final::after {
                    right: 160px - $times-width + 20px; } } } }


    @include media-breakpoint-down(md) {
        .Modal__header {
            margin-bottom: 0;
            h2 {
                margin-bottom: $val-xl-1; } }

        .ModalAdsReportToggleDate {
            justify-content: space-between;
            padding-bottom: $val-lg;
            margin-bottom: $val-lg;
            border-bottom: $bw-xs solid $c-light-sm; }

        &__header {
            margin-bottom: $val-mg-1; }

        &__toggle-object {
            padding-bottom: $val-lg;
            margin-bottom: $val-lg;
            border-bottom: $bw-xs solid $c-light-sm; }

        &__toggle-place {
            font-size: $fz-sm;
            font-weight: $fw-bold;
            color: $c-dark-lg;
            text-align: center;
            div {
                display: inline-block;
                width: 50%;
                border-bottom: $bw-xs solid $c-light-sm;
                cursor: pointer;
                padding-bottom: $val-sm-3;
                &.active {
                    color: $c-prim;
                    border-bottom-width: $bw-sm;
                    border-bottom-color: $c-prim; } } }

        &__grid {
            .start {
                min-width: 44px; }
            .title {
                width: 100%; }
            .time {
                min-width: 90px; }
            & > *:not(:last-child) {
                margin-right: $val-sm; } }

        .header {
            margin-bottom: $val-sm;
            .des {
                display: none; } }

        .reports {
            .report__item {
                $times-width: 44px;
                position: relative;
                padding: $val-sm-2 0;

                &:not(.final):nth-child(odd) {
                    &::before {
                        left: (44px + $val-sm) - $val-xs-1; }
                    .title::after {
                        background: rgb(255,255,255);
                        background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                        background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                        background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f6f6f8",GradientType=1); } }

                &:not(.final):nth-child(even) {
                    .title::after {
                        background: rgb(255,255,255);
                        background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
                        background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
                        background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%,  rgba(255,255,255,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); } }

                .title {
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        width: 30px; } }

                .time {
                    .timeDuration,
                    .timeLeft {
                        width: $times-width; }
                    .timeLeft {
                        &::before {
                            left: -7px; } } }

                &.final::after {
                    right: 90px - $times-width + 14px; } } } } }

.ModalAdsReportToggleDate {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: $val-sm-2; }

    .prev,
    .next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $val-md-1;
        height: $val-md-1;
        background-color: $c-light-md;
        border-radius: 500px;
        cursor: pointer;
        svg {
            width: $val-sm-3; } }
    .prev svg {
        transform: rotate(90deg); }
    .next svg {
        transform: rotate(-90deg); }

    .date {
        display: flex;
        align-items: center;
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark;
        svg {
            margin-right: $val-xs-1; } } }
