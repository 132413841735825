.FormDatepickerBetween {

    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        &__titles {
            display: none; }

        &__content {
            display: flex;
            & > * {
                padding-top: $val-sm-2;
                padding-bottom: $val-sm-2; }

            & > *:not(:last-child) {
                margin-right: $val-sm-2;
                padding-right: $val-sm-2;
                border-right: $bw-xs solid $c-light-sm; } } }

    @include media-breakpoint-down(md) {
        &__titles {
            width: 100%;
            display: flex;
            & > * {
                width: 100%;
                padding: $val-sm-1;
                text-align: center;
                cursor: pointer;
                font-family: $ff-title;
                font-weight: $fw-bold;
                font-size: $fz-lg;
                &:not(.active) {
                    color: $c-dark;
                    border-bottom: $bw-xs solid $c-dark-xs; }
                &.active {
                    color: $c-prim;
                    border-bottom: $bw-sm solid $c-prim; } } } }

    @include media-breakpoint-down(sm) {
        &__titles {
            & > * {
                font-size: $fz-md; } } } }
