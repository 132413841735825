.ButtonWhite {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $br-mg;
    border-width: 0;
    outline: none !important;
    cursor: pointer;
    background-color: $c-light;
    padding: $val-md-1;

    color: $c-dark-lg;
    font-weight: $fw-bold;
    font-size: $fz-xl;

    .spp-svg {
        margin-right: $val-sm;
        @include set-svg-size(md3);
        @include set-svg-color('dark'); }

    &:not(:active):not(.active) {
        @include box-shadow($bs-xs rgba($c-forth, .1)); }

    &.disabled, &:disabled {
        pointer-events: none;
        opacity: $o-xs; }

    &:active, &.active {
        background-color: rgba($c-light, $o-mg);
        @include box-shadow($bs-mr rgba($c-forth, .1)); }

    &_extend {
        width: 100%; } }
