@mixin volume-item-desktop {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .time,
    .info {
        font-family: $ff-title;
        font-size: $fz-sm; }

    .time {
        font-weight: $fw-normal;
        color: $c-dark; }

    .info {
        display: flex;
        justify-content: center;
        text-align: center;
        color: $c-dark-lg;
        width: 100%;
        max-width: 400px;
        margin-right: auto;
        .wrap {
            display: inline-block;
            margin-right: -($val-xs-1);
            .info-item {
                display: inline-block;
                white-space: nowrap;
                margin-right: $val-xs-1;
                span {
                    font-weight: $fw-bold;
                    color: $c-dark; } } } } }

@mixin volume-item-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .time {
        width: 25%; }
    .info,
    .StringDays {
        width: 75%; }

    .info {
        @include divider-bb;
        padding-bottom: $val-sm-1;
        margin-bottom: $val-sm-1; }

    .time,
    .info {
        font-family: $ff-title;
        font-size: $fz-sm; }

    .time {
        font-weight: $fw-normal;
        color: $c-dark; }

    .info {
        display: flex;
        white-space: nowrap;
        color: $c-dark-lg;
        .wrap {
            display: inline-block;
            margin-right: -($val-xs-1);
            .info-item {
                display: inline-block;
                white-space: nowrap;
                margin-right: $val-xs-1;
                span {
                    font-weight: $fw-bold;
                    color: $c-dark; } } } } }

@mixin volume-list-item-mobile {
    .List__item {
        display: flex;
        align-items: flex-end;
        .List__item-buttons {
            margin-left: $val-sm-1; } } }
