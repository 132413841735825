.List {

    &__header {
        &_elements {
            display: flex;
            justify-content: space-between; }
        &_pb-md {
            @include Collapsible-child-control-pb; }
        &_pb-sm {
            @include Collapsible-child-control-pb;
            @include media-tablet-and-up {
                padding-bottom: $val-xs-1; } }

        padding: {
            left: ($val-mr + $bw-md);
            right: ($val-mr + $bw-md); }

        @include media-breakpoint-up(lg) {
            padding: {
                left: ($val-xs-1 + $bw-md);
                right: ($val-xs-1 + $bw-md); } }

        @include media-breakpoint-down(md) {
            padding: {
                left: ($val-sm-1 + $bw-md);
                right: ($val-sm-1 + $bw-md); } } }

    &__label {
        @include lables-titles;
        margin-bottom: 0; }

    &__empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark-lg;
        padding: $val-xs; }

    // Модификации

    &_simple {

        // Подложка

        .List__content_white {
            @include box-shadow($bs-sm rgba($c-forth, $o-mr));
            overflow: hidden;
            border-radius: $bw-md;
            background-color: $c-light;

            @include media-breakpoint-down(md) {
                border-radius: $br-lg-2; } }

        // Элемент

        .List__item-buttons {
            width: auto;
            @include media-tablet-and-up {
                display: none; } }

        .List__item:not(.not-decorate) {
            display: flex;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:not(.selected):not(.highlighted):nth-child(2n) {
                background-color: $c-light-md; }
            &_text {
                font-family: $ff-title;
                font-size: $fz-sm;
                font-weight: $fw-bold;
                color: $c-dark; }

            &:first-child {
                &:not(.selected):not(.highlighted) {
                    margin-top: $bw-sm;

                    padding: $val-mr; } }

            @include media-breakpoint-up(lg) {
                padding: $val-mr $val-xs-1; }

            @include media-mobile {
                padding: $val-sm-2 $val-sm-1;
                &_text {
                    font-size: $fz-lg; } }

            // text-fade

            .List__text-fade {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 30px; } }

            &:nth-child(2n+1):not(.selected):not(.highlighted) {
                .List__text-fade::after {
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
                    background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); } }

            &:nth-child(2n):not(.selected):not(.highlighted) {
                .List__text-fade::after {
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                    background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f6f6f8",GradientType=1); } }

            &.selected {
                .List__text-fade::after {
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
                    background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff3e8",GradientType=1); } }

            &.highlighted {
                .List__text-fade::after {
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
                    background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff6ed",GradientType=1); } } } }

    &_cursor-pointer {
        .List__item {
            cursor: pointer; } } }
