.Notifications {
    // margin-top: 36px
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    &_add_mail {
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        background-color: #ffffff;
        border-radius: 4px;
        font-weight: 500;
        padding: 24px 16px;
        margin-right: 0;
        margin-bottom: 36px;

        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 48px;
        // justify-content: space-between
        align-items: end;
        & > *:first-child {
            flex: 1; }
 }        // font-size: 1rem
    &_settings_mail {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 36px;
        &_element {
            // flex: 0.5
            display: flex;
            flex-direction: row;
            gap: 48px;
            min-width: 35%;
            align-items: end;
            // & > *:first-child
            //     flex: 1
            h4 {
                color: rgba(10, 10, 10, 0.48); }
            &_tags {
                flex: 1;
                padding: 6px;
                border-bottom: 1px solid black;
                &_items {
                    padding: 6px; } }
            &_add {
                flex: 0.5;
                display: flex;
                flex-direction: row;
                align-items: end;
                & > *:first-child {
                    flex: 1; } } } } }
