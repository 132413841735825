.FormDatepickerDurationInput {
    font-family: $ff-title;
    min-width: $val-mg-2;

    &__title {
        margin-bottom: $val-md-3;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        text-transform: uppercase;
        color: $c-dark-lg;
        text-align: center; }

    &__input {
        input {
            text-align: center;
            min-width: $val-mg-1; } }

    @include media-breakpoint-down(md) {
        display: none; } }
