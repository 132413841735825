.Line {
    cursor: pointer;

    // &.selected
    //     background-color: $c-light-lg
    //     border-radius: $br-md
    // &.selected &__number,
    // &.selected &__title
    //     color: $c-dark-lg

    &:nth-child(2n):not(.selected) {
        background-color: $c-light-md; }

    &__number,
    &__title {
        font-family: $ff-title; }

    &__number {
        font-weight: $fw-normal; }

    &__title {
        font-weight: $fw-bold; }

    @include media-breakpoint-up(lg) {
        padding: $val-sm-1 $val-xs;
        &__number,
        &__title {
            font-size: $fz-sm; }
        &__number {
            color: $c-dark-lg; }
        &__title {
            color: $c-dark; } }

    @include media-breakpoint-down(md) {
        padding: $val-md-2 $val-xs-1;
        &__number,
        &__title {
            color: $c-dark;
            font-size: $fz-lg; } } }
