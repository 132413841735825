.SettingsBackgroundLists__mobileControls {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -$val-xs
    margin-bottom: -$val-xs;
    & > * {
        // margin-right: $val-xs
        margin-bottom: $val-xs;
        min-width: 0 !important; }

    .remove {
        width: calc(50% - #{$val-xs/2});
        margin-right: $val-xs; }
    .save {
        width: calc(50% - #{$val-xs/2}); }
    .add {
        width: calc(50% - #{$val-xs/2});
        margin-right: $val-xs; }
    .moveup {
        width: calc(24% - #{$val-xs/2});
        margin-right: $val-xs; }
    .movedown {
        width: calc(24% - #{$val-xs/2}); }

    .moveup,
    .movedown {
        .spp-svg {
            margin-right: 0; } } }
