@mixin videolist-modile {
    &:not(.warning) {
        .warning {
            display: none; } }

    &__header {
        position: relative;

        .Videolists__grid {
            padding: $val-md $val-xs-1;

            .tracks_count {
                order: 2; }
            .size {
                order: 3; }
            .name {
                order: 1; }
            .duration {
                order: 4; }

            .play,
            .warning,
            .open {
                & > * {
                    margin-top: 0; } }
            .play,
            .warning {
                svg {
                    height: 26px; } }
            .open {
                svg {
                    height: $val-sm-1; } }

            .name {
                .desktop {
                    display: none; }
                .mobile {
                    display: block; } }

            .tracks_count {
                flex-wrap: wrap;
                .from,
                .to {
                    display: block;
                    width: 100%;
                    &:after {
                        display: none; } } } } } }

.Videolist {
    font-family: $ff-title;
    overflow: hidden;

    // Состояния

    &_gray:not(.selected) {
        background-color: $c-light-md; }

    &_gray,
    &.selected {
        .Videolist__List .item:nth-child(2n+1) {
            background-color: $c-light; } }

    &:not(&_gray):not(.selected) {
        .Videolist__List .item:nth-child(2n+1) {
            background-color: $c-light-md; } }

    &.clip-ended,
    &.clip-deleted {
        .name {
            opacity: $o-lg; } }
    &:not(.warning) {
        .warning {
            opacity: 0;
            pointer-events: none;
            visibility: hidden; } }
    &.track-is-new {
        .name > * {
            color: #4ed479 !important; } }

    &.opened {
        .open i {
            transform: rotate(180deg); } }
    &:not(.opened) {
        & > *:not(.Videolist__header) {
            display: none; } }

    &.disabled, &:disabled {
        & > * {
            opacity: $o-xs;
            pointer-events: none;
            cursor: default; } }

    // .name-fade

    &_gray.name-fade:not(.selected) {
        .Videolists__grid > .name {
            &::after {
                background: rgb(255,255,255);
                background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(246,246,248,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f6f6f8",GradientType=1); } } }
    &.name-fade:not(&_gray) {
        .Videolists__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); } }
    &.name-fade.selected {
        .Videolists__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 243, 232, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff3e8",GradientType=1); } }
    &.name-fade.highlighted:not(.selected) {
        .Videolists__grid > .name::after {
            background: rgb(255,255,255);
            background: -moz-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 50%, rgba(255, 246, 237, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fff6ed",GradientType=1); } }

    // Элементы

    &.playing &__header {
        .Videolists__grid .play .icon-play {
            display: none; } }

    &:not(.playing) &__header {
        .Videolists__grid .play .icon-pause {
            display: none; }
        .rewind {
            z-index: -1; } }

    &__header {
        position: relative;

        .Videolists__grid {
            padding: $val-sm-1 $val-mr;
            & > * {
                position: relative;
                z-index: 3; }

            .size {
                display: flex;
                font-size: $fz-sm;
                color: $c-dark-lg;
                font-weight: $fw-normal; }

            .play {
                z-index: 5;
                display: flex;
                justify-content: center;
                & > * {
                    margin-top: -2px; } }

            .name {
                position: relative;
                margin-bottom: 1px;
                h4 {
                    font-size: $fz-sm;
                    color: $c-dark;
                    font-weight: $fw-bold;
                    margin: 0; }
                .desktop {
                    display: block; }
                .mobile {
                    display: none; }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 30px; } }

            .tracks_count {
                display: flex;
                // justify-content: center
                color: $c-dark;
                font-size: $fz-xs;
                font-weight: $fw-normal;
                // text-align: center
                cursor: default;
                .from {
                    opacity: $o-xs;
                    &::after {
                        content: '/'; } } }

            .duration {
                display: flex;
                // justify-content: center
                cursor: default;
                svg {
                    height: 14px; }
                & > * {
                    margin-top: -2px; } }

            .open {
                z-index: 5;
                display: flex;
                justify-content: flex-end;
                i {
                    transition: .2s; }
                & > * {
                    margin-top: -4px; } }

            .name,
            .play svg,
            .open svg {
                &:hover {
                    cursor: pointer;
                    &:not(:active) {
                        opacity: $o-xl; } } } }

        // Ползунок

        .slider-1 {
            $c-start: rgba(255,236,238,1);
            $c-end: rgba(255,197,203,1);
            position: absolute;
            transition: .2s;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: rgb(255,236,238);
            background: -moz-linear-gradient(90deg, $c-start 0%, $c-end 100%);
            background: -webkit-linear-gradient(90deg, $c-start 0%, $c-end 100%);
            background: linear-gradient(90deg, $c-start 0%, $c-end 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFECEE",endColorstr="#ffc5cb",GradientType=1); }
        .slider-2 {
            position: absolute;
            transition: .2s;
            top: 0;
            bottom: 0;
            z-index: 2;
            display: block;
            width: $bw-xs;
            background-color: $c-prim; }

        // Перемотка

        .rewind {
            position: absolute;
            z-index: 4;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background-color: transparent; } }

    &__List {
        margin: $val-mr;
        margin-top: 0;
        margin-right: $val-xs-1 + 1px;

        .ScrollContainer__more {
            margin-top: $val-sm-1;
            padding-bottom: $val-lg - $val-mr; }

        .item {
            display: flex;
            align-items: center;
            padding: $val-mr $val-sm-1;

            .number,
            .from,
            .date,
            .plays {
                font-size: $fz-sm;
                font-weight: $fw-normal; }

            .number,
            .from,
            .plays {
                color: $c-dark-lg; }

            .date {
                color: $c-dark; }

            .number {
                width: 28px; }

            .from {
                margin-right: $val-mr;
                span {
                    display: none; } }

            .date {
                margin-right: $val-sm-1; }

            .plays {
                position: relative;
                margin-left: auto;
                margin-right: $val-sm-1;
                span {
                    display: none; }
                &::before {
                    $v: 6px;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -($val-mr+$v);
                    display: block;
                    width: $v;
                    height: $v;
                    transform: translateY(-50%);
                    background-color: $c-third;
                    border-radius: 500px; } } } }

    audio {
        display: none; }

    @include media-breakpoint-down(lg) {
        @include track-modile; } }
