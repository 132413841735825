.StringDays {
    font-family: $ff-title;
    font-weight: $fw-bold;
    font-size: $fz-sm;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -($val-mr);
        margin-bottom: -($val-mr);
        & > * {
            margin-right: $val-mr;
            margin-bottom: $val-mr; }

        .active {
            color: $c-prim; }

        & > *:not(.active) {
            color: $c-dark-lg;
            text-decoration: line-through; } } }
