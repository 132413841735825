.NavbarCollapse {
    $svg-size: $val-sm+($val-mr/2);
    z-index: 10;

    &__menu {
        @include box-shadow($bs-lg $c-dark-sm);
        transition: .2s;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        max-width: 420px;
        min-width: 340px;
        background-color: $c-light;
        padding: ($val-mg-1+$val-sm) $val-lg $val-mg-1;
        box-sizing: border-box;
        &:not(.active) {
            transform: translateX(110%); }

        .close {
            position: absolute;
            top: $val-md-2;
            @each $size, $key in $container-offset-sizes {
                @include media-breakpoint-only($size) {
                    right: map-get($container-offset-sizes, $size); } } }

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .top {
                overflow-y: auto;
                margin-bottom: $val-lg; } }

        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                &:not(:last-child) {
                    margin-bottom: $val-lg; } } }
        a {
            font-family: $ff-title;
            font-size: $fz-xl;
            font-weight: $fw-bold;
            text-transform: uppercase;
            color: $c-dark;
            text-decoration: none; }

        .close {
            svg {
                height: $svg-size; } } }

    .open {
        svg {
            height: $svg-size;
            width: $svg-size; }
        img {
            height: $svg-size;
            width: auto; } } }
