.FormSelectColor {
    position: relative;

    .form-group input.form-control.active {
        padding-left: ($val-sm-1 + $val-mr) !important; }

    &__item {
        position: relative;
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        color: $c-dark;
        padding: 7px 4px 7px ($val-sm-1 + $val-mr + 7px);
        cursor: pointer;
        &:hover {
            color: rgba($c-dark, $o-xl); }
        &.selected {
            color: rgba($c-dark, $o-xl);
            background-color: $c-light-md; }

        .color {
            left: 7px; } }

    .color {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 0;
        width: $val-sm-1;
        height: $val-sm-1;
        border-radius: $br-sm; } }
