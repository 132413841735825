.FormTextReadOnly {

    &__text {
        border-color: transparent !important;
        box-shadow: none !important;
        padding: 0 !important; }

    &__comment {
        font-family: $ff-title;
        color: $c-dark-lg;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        margin-top: $val-sm-2; } }
