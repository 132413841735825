.MusicsReport__grid {
    display: flex;
    align-items: center;
    height: 35px;
    @include media-breakpoint-down(sm) {
        height: 80px; }

    .timestamp {
        width: 100%;
        max-width: 130px;
        white-space: nowrap; }
    .list {
        text-align: left;
        width: 100%;
        overflow: hidden; }
    .song {
        width: 100%; }

    & > *:not(:last-child) {
        margin-right: $val-sm-1; } }
