.FormInputIPAddress {

    &__inputs {
        display: flex;
        align-items: center;

        .FormInputNumber {
            max-width: $val-xl-3;
            &:not(:last-child) {
                margin-right: $val-sm; } } }

    &__gate-divider {
        font-family: $ff-title;
        font-weight: $fw-bold;
        color: $c-dark-md;
        text-align: center;
        margin: {
            right: ($val-xs-1 - 2px);
            left: -($val-sm - $val-xs-1); } } }
