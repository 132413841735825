.ButtonCircle {

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $br-mg;
    border-width: 0;
    outline: none !important;
    cursor: pointer;
    .spp-svg {
        @include set-svg-color('dark'); }

    &:not(:active):not(.active) {
        @include box-shadow($bs-xs rgba($c-forth, .1)); }

    &.disabled, &:disabled {
        pointer-events: none;
        opacity: $o-xs; }

    &:not(&_light) {
        background-color: rgba($c-light, $o-lg); }
    &_light {
        background-color: $c-light; }

    &:active, &.active {
        background-color: rgba($c-light, $o-mg);
        @include box-shadow($bs-mr rgba($c-forth, .1)); }

    // Размеры

    &_md {
        width: $val-md-1;
        height: $val-md-1;
        min-width: $val-md-1;
        min-height: $val-md-1;
        .spp-svg {
            @include set-svg-size(sm1); } }

    &_sm {
        width: $val-sm-2;
        height: $val-sm-2;
        min-width: $val-sm-2;
        min-height: $val-sm-2;
        .spp-svg {
            @include set-svg-size(xs1); } } }
