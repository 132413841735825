.SettingsBackgroundLists {

    &__wrap {
        display: flex;
        & > * {
            width: 48%; }
        & > *:not(:last-child) {
            margin-right: 4%; } }

    &__item {
        display: flex;
        align-items: center;
        width: 100%;

        .icon {
            margin-right: $val-mr;
            svg {
                width: $val-sm-1; } }

        .title {
            flex-grow: 1;
            font-family: $ff-title;
            font-weight: $fw-bold;
            font-size: $fz-sm;
            color: $c-dark;
            white-space: nowrap;
            overflow: hidden; } } }
