@mixin place-expanded {
    @include media-tablet {
        .Space__Place.expanded {
            @content; } } }

@mixin place-expanded-desktop {
    @include media-desktop {
        .Space__Place.expanded {
            @content; } } }

@mixin place-not-expanded {
    @include media-tablet-and-up {
        .Space__Place:not(.expanded) {
            @content; } } }
