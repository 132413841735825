@mixin create-buttons-shadow($btns) {
    @each $color in $btns {
        .btn-#{$color}:not(.btn-light) {

            // SVG-иконки
            .spp-svg:not([class*='spp-svg-color']) {
                @include set-svg-color('light'); }

            // Отключено
            &:disabled,
            &.disabled {
                opacity: $o-xs !important;
                background-color: $c-dark !important;
                border-color: $c-dark !important;
                box-shadow: none !important; }

            // Статичное
            &:not(:hover):not(:active):not(.active):not(:disabled):not(.disabled) {
                box-shadow: $bs-lg rgba( map-get($theme-colors, $color), .2) !important; }

            // Наведение
            &:hover:not(:active):not(.active):not(:disabled):not(.disabled) {
                box-shadow: $bs-lg rgba( map-get($theme-colors, $color), .2) !important; }

            // Клик/активное
            &:active,
            &.active {
                background-color: map-get($theme-colors, $color) !important;
                border-color: map-get($theme-colors, $color) !important;
                box-shadow: $bs-sm rgba( map-get($theme-colors, $color), .2) !important; } } } }


@mixin set-light-button-styles {
    background-color: $c-light !important;
    border-color: $c-light-sm !important;

    // SVG-иконки
    .spp-svg:not([class*='spp-svg-color']) {
        @include set-svg-color('dark'); }

    // Отключено
    &:disabled,
    &.disabled {
        opacity: $o-md !important;
        box-shadow: $bs-lg rgba($c-forth, .1) !important; }

    // Статичное
    &:not(:hover):not(:active):not(.active):not(:disabled):not(.disabled) {
        box-shadow: $bs-lg rgba($c-forth, .1) !important; }

    // Наведение
    &:hover:not(:active):not(.active):not(:disabled):not(.disabled) {
        box-shadow: $bs-lg rgba($c-forth, .1) !important; }

    // Любое, кроме клик/активное
    &:not(:active):not(.active) {
        color: $c-dark-lg !important; }

    // Клик/активное
    &:active,
    &.active {
        color: $c-dark !important;
        box-shadow: $bs-sm rgba($c-forth, .1) !important; } }
