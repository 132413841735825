.FormCycleTimeTime {
    font-family: $ff-title;
    font-size: $fz-sm;

    &__title {
        font-weight: $fw-normal;
        text-align: center;
        text-transform: uppercase;
        color: $c-dark-lg; }

    &__content {
        display: flex;
        justify-content: center;
        margin: $val-sm-2 0; }

    &__times {
        width: $val-lg-1;
        margin-right: $val-mr;
        .time {
            text-align: center;
            cursor: pointer;
            font-weight: $fw-bold;
            &:not(:last-child) {
                margin-bottom: $val-sm-1; }

            &_1, &_2, &_4, &_5 {
                height: 16px; }

            &_1,
            &_5 {
                color: rgba($c-dark, .16); }
            &_2,
            &_4 {
                color: $c-dark-md; }
            &_3 {
                margin-top: -$val-mr-2;
                & + * {
                    margin-top: -$val-mr-2; }
                input {
                    width: $val-lg-1 !important;
                    text-align: center; } } } }

    &__buttons {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & > * {
            cursor: pointer; }
        .disabled {
            opacity: $o-md;
            pointer-events: none;
            cursor: default; } }

    @include media-mobile {
        &__title {
            font-size: $fz-md; }

        &__content {
            margin: $val-lg-1 0 $val-mg-3; }

        &__times {
            width: $val-mg-3;

            .time {
                &:not(:last-child) {
                    margin-bottom: $val-lg; }

                &_1, &_2, &_4, &_5 {
                    font-size: $fz-lg;
                    height: 22px; }
                &_3 {
                    input {
                        font-size: $fz-xl-1 !important;
                        height: 40px !important;
                        width: $val-mg-3 !important; } } } }

        &__buttons {
            svg {
                height: $val-sm-1 !important; } } } }
