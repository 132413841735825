// Шрифты
$font-family-sans-serif:      $ff-title;
$font-family-base:            $ff-title;

$font-size-base:              $fz-basic;

$font-size-lg:                $fz-lg;
$font-size-sm:                $fz-xs;
$h1-font-size:                $fz-mg;
$h2-font-size:                $fz-xl;
$h3-font-size:                $fz-md;
$h4-font-size:                $fz-md;
$h5-font-size:                $fz-md;
$h6-font-size:                $fz-xs;

$font-weight-base:            $fw-normal;
$font-weight-lighter:         $fw-light;
$font-weight-light:           $fw-light;
$font-weight-normal:          $fw-normal;
$font-weight-bold:            $fw-bold;
$font-weight-bolder:          $fw-bold;

$line-height-base:            $lh-text;
$line-height-lg:              $lh-text;
$line-height-sm:              $lh-title;

// Цвета
$primary:                     $c-prim;
$secondary:                   $c-sec;
$success:                     #20c997;
$info:                        $c-third;
$warning:                     $c-sec;
$danger:                      $c-prim;
$light:                       $c-light;
$dark:                        $c-dark;

$theme-colors: (          
    "primary":                $primary,
    "secondary":              $secondary,
    "success":                $success,
    "info":                   $info,
    "warning":                $warning,
    "danger":                 $danger,
    "light":                  $light,
    "dark":                   $dark,
    "prim":                   $c-prim,
    "prim-lighter":           $c-prim-lighter,
    "sec":                    $c-sec,
    "third":                  $c-third,
    "forth":                  $c-forth,
    "dark-lg":                $c-dark-lg,
    "dark-md":                $c-dark-md,
    "dark-sm":                $c-dark-sm,
    "dark-xs":                $c-dark-xs,
    "light-lg":               $c-light-lg,
    "light-md":               $c-light-md,
    "light-sm":               $c-light-sm,
    "light-xs":               $c-light-xs
);   

// Отступы
$spacer:                       1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    mg:  $val-mg,
    mg1: $val-mg-1,
    mg2: $val-mg-2,
    xl:  $val-xl,
    xl1: $val-xl-1,
    xl2: $val-xl-2,
    xl3: $val-xl-3,
    lg:  $val-lg,
    md:  $val-md,
    md1: $val-md-1,
    md2: $val-md-2,
    md3: $val-md-3,
    sm:  $val-sm,
    sm1: $val-sm-1,
    sm2: $val-sm-2,
    sm3: $val-sm-3,
    xs:  $val-xs,
    xs1: $val-xs-1,
    mr:  $val-mr,
    mr1: $val-mr-1,
    mr2: $val-mr-2
);       
          
// Скригления
$border-radius:               $br-md;
$border-radius-lg:            $br-lg;
$border-radius-sm:            $br-sm;

// Тени
$enable-shadows:              true;
$box-shadow:                  $bs-md;
$box-shadow-lg:               $bs-lg;
$box-shadow-sm:               $bs-sm;

// Брейкпоинты
$grid-breakpoints: (
    xs: $bp-xs,
    sm: $bp-sm,
    md: $bp-md,
    lg: $bp-lg,
    xl: $bp-xl,
    mg: $bp-mg
);

// Контейнер
$container-max-widths: (
    sm: $container-sm,
    md: $container-md,
    lg: $container-lg,
    xl: $container-xl,
    mg: $container-mg
);

// Кнопки
$input-btn-padding-y:         0.688rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-family:       $ff-title;
$input-btn-font-size:         $fz-sm;
$input-btn-line-height:       1.5;
$input-placeholder-color:      #B7B7B7;
$input-placeholder-family:      "CRC35";
$input-placeholder-font-size:    "14px";


$input-btn-focus-width:       0;
// $input-btn-focus-color:       rgba($component-active-bg, .25) !default;
// $input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-border-width:      $bw-xs;

// /////////////////////////====================================

$btn-font-weight:             $fw-bold;
$btn-box-shadow:              0 8px 20px rgba($c-prim, .2);
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
// $btn-disabled-opacity:        .65 !default;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius:           $br-mg;
$btn-border-radius-lg:        $br-mg;
$btn-border-radius-sm:        $br-mg;