
// Общие

.ObjectScheduleGroupSmartMediaList__grid {
    display: flex;
    align-items: center;
    width: 100%;

    & > *:not(.tracks):not(:last-child) {
        margin-right: $val-xs-1; }

    .double {
        display: flex;
        flex-wrap: wrap;
        margin-right: -($val-sm);
        margin-bottom: -($val-mr);
        flex-grow: 1;

        &__item {
            display: flex;
            align-items: center;
            margin-right: $val-md-1;
            margin-bottom: $val-mr;
            //width: 44%
            .left {
                margin-right: $val-mr;
                .icon {
                    width: $val-sm-1;
                    min-width: $val-sm-1;
                    text-align: center; } }
            .right {
                flex-grow: 1;
                white-space: nowrap; } } }

    .far {
        margin-left: auto;
        width: 180px; } }

// Расширенный вид

@mixin component-extended {
    @include media-desktop {
        @include v-medialist-extend; }

    @include media-tablet {
        @include v-medialist-simple; } }

// Простой

@mixin component-simple {
    @include media-tablet-and-up {
        @include v-medialist-simple; } }

@include place-expanded-desktop {
    .ObjectScheduleGroupSmartMediaList {
        @include v-medialist-extend;
        .double__item {
            width: 45%; } } }

@include place-expanded {
    .ObjectScheduleGroupSmartMediaList {
        @include v-medialist-simple;
        .double__item {
            width: 45%; }
        .double__item .right {
            width: 240px;
            min-width: 70px; } } }

@include place-not-expanded {
    .ObjectScheduleGroupSmartMediaList {
        @include v-medialist-simple; } }

// Мобильные

.ObjectScheduleGroupSmartMediaList {
    @include media-mobile {
        @include v-medialist-mobile; } }
