.was-validated .form-control {
    background-image: none !important; }
.slider-parent {

    @media screen and ( max-width: 768px  ) and ( max-height: 1024px  ) {
        display: none !important; }
    @media screen and ( max-height: 400px) and (max-width: 900px) {
        display: block !important; } }

.slider {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media screen and ( max-height: 400px) and (max-width: 900px) {
        z-index: 3; }

    // margin-top: 140px
    .imaga {
        filter: brightness(60%);
        height: 100%;
        .image-view {
            position: absolute;
            right: 0;
            // @include media-breakpoint-up(lg)
            @media screen and ( max-width: 1600px  ) {
                width: 92%!important; }
            @media screen and ( max-width: 1340px  ) {
                width: 88%!important; }
            @media screen and ( max-width: 1280px  ) {
                width: 83%!important; }
            @media screen and ( max-width: 1024px  ) {
                width: 74%!important; }
            @media screen and ( max-height: 400px) and (max-width: 900px) {
                width: calc(100% - 163px) !important; }
            @media screen and ( max-height: 400px) and (max-width: 700px) {
                width: calc(100% - 192px) !important; } } }

    &-img {
        position: absolute;
        width: 100%;
        height: 100%;
        // transition: all 2s ease-in-out
        transition: 1s; }

    &-img-prev {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 1s;
        // transform: ease-in-out
        .dots div {
            display: none !important; } }
    &-img-next {
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 1s;
        // transform: ease-in-out
        .dots div {
            display: none !important; } }
    .text-img {
        position: absolute;
        top: 50%;
        left: 39%;
        width: 573px;
        height: 424px;
        transform: translate(-50%, -50%);
        overflow: hidden;
        z-index: 0;
        @media screen and ( max-width: 1600px  ) {
            left: 54%; }
        @media screen and ( max-width: 1280px  ) {
            left: 60%; }
        @media screen and ( max-width: 1024px  ) {
            left: 66%;
 }            // transform: scale(0.9)
        @media screen and ( max-height: 1366px) and (max-width: 1024px) {
            transform: scale(0.9);
            top: 34%;
            left: 32%; }
        @media screen and ( max-height: 400px) and (max-width: 900px) {
            transform: scale(0.7);
            top: -17%;
            left: 19%; }
        @media screen and ( max-height: 400px) and (max-width: 700px) {
            transform: scale(0.56);
            left: 16.5%; }
        .text-number {
            position: absolute;
            left: 0;
            top: 0;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
            span {
                font-size: 14px !important; } }
        .text-title {
            position: absolute;
            left: 0%;
            top: 28.83%;
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 62px;
            text-align: left;
            // font-size: 42px
            line-height: 60px;
            color: #FFFFFF; }
        .text-description {
            position: absolute;
            left: 0px;
            bottom: 25%;
            font-family: CRC35;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            text-align: left;
            //line-height: 35px
            line-height: 24px;
            color: #FFFFFF; } }

    .dots div:not(:first-child) {
        margin-left: 20px; }
    .dots {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1rem 0;
        z-index: 100;
        list-style-type: none;
        position: absolute;
        bottom: 0;

        li {
            margin: 0 0.25rem; }

        button {
            // height: 8px
            // width: 8px
            display: block;
            width: 8px;
            height: 8px;
            padding: 0;
            z-index: 100;

            border: none;
            border-radius: 100%;
            background-color: orange;

            text-indent: -9999px; }

        div {
            // height: 8px
            // width: 8px
            display: block;
            width: 8px;
            height: 8px;
            padding: 0;
            // z-index: 100

            border: none;
            border-radius: 100%;
            background-color: orange;

            text-indent: -9999px; }

        li.slick-active button {
            background-color: red; } } }

.Login {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    background: #ECEDEF;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    .wrong {
        display: block;
        color: #ff0d25;
        font-family: CRC55;
        margin-top: 24px; }
    .Login-slider {
        position: absolute;
        // width: 1533px
        // height: 860px
        left: 387px;
        top: 140px; }
    .form-recovery {
        height: 336px !important; }
    .Login-form {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 52px 42px;
        position: absolute;
        background: #FFFFFF;
        border-radius: 10px;
        left: 80px;
        //top: calc(100%-140px)
        align-self: center;
        width: 28%;
        max-width: 533px;

        //width: 420px
        height: auto;
        //height: 516px
        // @include media-breakpoint-down(xl)
        //     width: 32%
        @include media-breakpoint-down(xl) {
            width: 35%; }
        @media screen and ( max-width: 1024px  ) {
            width: 38% !important; }
        @media screen and ( max-width: 768px  ) {
            width: 100% !important;
            align-self: center;
            left: 0px;
            max-width: 100%; }
        @media screen and ( max-height: 700px  ) {
            transform: scale(0.83); }
        @media screen and ( max-height: 800px  ) {
            transform: scale(0.98); }
        @media screen and ( max-height: 755px  ) {
            transform: scale(0.86);
            button {
                height: auto !important; } }
        @media screen and ( max-height: 400px) and (max-width: 900px) {
            transform: scale(0.5);
            width: 100% !important;
            left: -148px;
            max-width: 630px;
            height: 600px; }

        @media screen and ( max-width: 420px  ) {
            // top: 100px
            padding: 22px 12px;
            button {
                line-height: 2px !important; } }

        &__header {
            font-family: Rubik;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: left;
            span {
                color: #ff0d25; }
            @media screen and ( max-width: 420px  ) {
                font-size: 24px; } }
        &__label {
            margin-top: 20px;
            margin-bottom: 13px;
            color: #5C5C5C;
            font-family: CRC35;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px; }
        .Login-btn {
            font-family: CRC35;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            height: 60px;
            border-radius: 10px;
            margin-top: 20px; }
        .forget-password {
            // position: absolute
            cursor: pointer;
            display: block;
            font-family: CRC55;
            font-style: normal;
            font-weight: normal;
            text-align: right;
            font-size: 14px;
            line-height: 21px;
            text-decoration-line: underline;
            margin-top: 20px;
            width: 24%;
            margin-left: auto;
            @include media-breakpoint-down(xl) {
                width: 30%; }
            @media screen and ( max-width: 420px  ) {
                width: 40%; } }
        .forget-password:hover {
            color: #ff0d25; }
        .license {
            display: inline-block;
            font-family: CRC35;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            margin-top: 20px;
            color: #898989; }
        .eye {
            bottom: 23px !important;
            right: 23px !important;

            svg {
                // fill: #B7B7B7
                height: 14px !important;
                & > * {
                    fill: #B7B7B7; } } }
        .eye-shown {
            svg {
                & > * {
                    fill: black; } } }
        .invalid-feedback {
            margin-left: 16px !important;
            margin-top: 16px !important; }
        .recovery-notify {
            display: flex;
            padding-top: 20px;
            &-text {
                font-family: CRC55;
                font-style: normal;
                font-weight: normal;
                margin-left: 12px;
                font-size: 12px;
                line-height: 17px;
                color: black; }
            span {
                font-family: CirceExtraBold; } }
        .back-recovery {
            cursor: pointer;
            display: block;
            font-family: CRC55;
            font-style: normal;
            font-weight: normal;
            text-align: left;
            font-size: 14px;
            line-height: 21px;
            text-decoration-line: underline;
            margin-top: 24px;
            width: 24%;
            margin-right: auto;
            @include media-breakpoint-down(xl) {
                width: 30%; }
            @media screen and ( max-width: 420px  ) {
                width: 40%; } }
        .back-recovery:hover {
            color: #ff0d25; } }

    .Login-page__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 48px 80px;
        background: #FFFFFF;
        position: absolute;
        width: 100%;
        height: 140px;
        left: 0px;
        top: 0px;
        @media screen and ( max-height: 400px) and (max-width: 900px) {
            z-index: 2; }
        @media screen and ( max-width: 420px  ) {
            // min-height: 10em
            // display: table-cell
            // vertical-align: middle
            padding: 40px 50%;
            align-items: center;
            width: 100%; }
        @media screen and ( max-height: 400px) {
            height: 30px;
            padding: 4px 30px; } }

    .form-groupp {
        position: relative;
        // $enable-validation-icons: false
        input:placeholder-shown {
            font-size: 14px !important;
            font-family: CRC35 !important;
            // margin-left: 18px
            line-height: 21px; }

        .form-label {
            @include lables-titles; }

        input.form-control,
        textarea.form-control {
            position: relative;
            z-index: 2;
            // box-shadow: none !important
            height: auto;
            font-weight: $fw-bold;
            font-size: $fz-sm;
            font-family: $ff-title;
            padding: 0.688rem 0.75rem;
            // background-color: transparent !important
            line-height: 1; }

        &.icon {
            div.form-control,
            input.form-control,
            textarea.form-control {
 } }                // padding-right: $val-sm !important

        &:not(.italic) {
            div.form-control,
            input.form-control,
            textarea.form-control {
                font-weight: $fw-bold; } }

        &.italic {
            div.form-control,
            input.form-control,
            textarea.form-control {
                font-weight: $fw-normal;
                font-style: italic; } }

        & > div > .spp-svg,
        & > .spp-svg {
            position: absolute;
            right: 0;
            bottom: $val-mr;
            z-index: 1;
            &:not([class*='spp-svg-size']) {}
            @include set-svg-size(sm1); } }

    //
    // Цвета
    //

    //  &:not(.light)

    //      .form-label
    //          color: $c-dark-lg

    //      div.form-control,
    //      input.form-control,
    //      textarea.form-control

    //          &.disabled, &:disabled
    //              &, & + .spp-svg
    //                  @include form-disabled

    //          &:not(:hover):not(.disabled):not(:disabled):not(:focus):not(.active):not(:active)
    //              @include input-placeholder-color( $c-dark-lg )
    //              @include input-value-color( rgba($c-dark, $o-xl) )
    //              border-bottom-color: $c-dark

    //      &:hover:not(:focus):not(.active):not(:active)
    //          @include input-placeholder-color( $c-dark-lg )
    //              @include input-value-color( rgba($c-dark, $o-xl) )
    //              border-bottom-color: rgba($c-dark, $o-xl)
    //              & + .spp-svg
    //                  opacity: $o-xl

    //          &:focus, &.active, &:active
    //              @include input-placeholder-color( rgba($c-dark, $o-xl) )
    //              @include input-value-color( rgba($c-dark, $o-xl) )
    //              border-bottom-color: rgba($c-dark, $o-xl)

    //      *:not(.ButtonCircle) .spp-svg:not([class*='spp-svg-color'])
    //          @include set-svg-color('dark')

    //  &.light

    //      .form-label
    //          color: rgba($c-light, $o-md)

    //      div.form-control,
    //      input.form-control,
    //      textarea.form-control

    //          &.disabled, &:disabled
    //              &, & + .spp-svg
    //                  @include form-disabled

    //          &:not(:hover):not(.disabled):not(:disabled):not(:focus):not(.active):not(:active)
    //              @include input-placeholder-color( rgba($c-light, $o-md) )
    //              @include input-value-color( $c-light )
    //              border-bottom-color: $c-light

    //          &:hover:not(:focus):not(.active):not(:active)
    //              @include input-placeholder-color( rgba($c-light, $o-md) )
    //              @include input-value-color( $c-light )
    //              border-bottom-color: rgba($c-light, $o-xl)
    //              & + .spp-svg
    //                  opacity: $o-xl

    //          &:focus, &.active, &:active
    //              @include input-placeholder-color( $c-light )
    //              @include input-value-color( $c-light )
    //              border-bottom-color: $c-light

    //      & > div > .spp-svg:not([class*='spp-svg-color']),
    //      & > .spp-svg:not([class*='spp-svg-color'])
    //          @include set-svg-color('light')

    //  fieldset:disabled
    //      .form-label,
    //      .form-control
 }    //          @include form-disabled

// @-webkit-keyframes slide-left
//   0%
//     -webkit-transform: translateX(0)
//     transform: translateX(0)

//   100%
//     -webkit-transform: translateX(0)
//     transform: translateX(0)

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%); }

    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%); } }
.slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.895, 0.030, 0.685, 0.220) both;
    animation: slide-left 0.5s cubic-bezier(0.895, 0.030, 0.685, 0.220) both; }

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-200%) translateY(-50%);
        transform: translateX(-200%) translateY(-50%); }
    100% {
        -webkit-transform: translateX(-37%) translateY(-50%);
        transform: translateX(-37%) translateY(-50%); } }
.slide-right {
    // -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both
    animation: slide-right 0.5s cubic-bezier(0.895, 0.030, 0.685, 0.220) both; }
@keyframes slide-right-inner {
    0% {
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%); }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0); } }
.slide-right-inner {
    // -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both
    animation: slide-right-inner 0.5s cubic-bezier(0.895, 0.030, 0.685, 0.220) both; }
