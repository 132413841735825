.ContentWhat {

    @include media-breakpoint-up(lg) {
        &.search-active {
            .CollapsibleMusic,
            .CollapsibleVideo {
 } } }                // display: none

    @include media-breakpoint-up(mg) {
        &.expanded-content {
            // &:not(.search-active)
            div {
                .CollapsibleMusic {
                    min-width: 650px; } }
            div {
                .CollapsibleVideo {
                    max-width: 650px; } } } }

    @media (min-width: 1500px) {
        &.expanded-content {
            display: flex;

            // &:not(.search-active)
            .CollapsibleMusic {
                max-width: 550px; }
            .CollapsibleVideo {
                max-width: 550px; }

            .Collapsible {
                width: 100%;
                &:not(:last-child) {
                    margin-right: $val-md-3; }

                .Collapsible__desktop .toggle-active {
                    display: none !important; } } } } }
