html {
    overflow-x: hidden; }
@font-face {
    font-family: "CirceBold";
    src: local("CirceBold");
    src: url("./assets/fonts/Circe/Circe/Circe-Bold.otf") format("opentype");
    font-weight: bold; }
@font-face {
    font-family: "CirceExtraBold";
    src: local("CirceExtraBold");
    src: url("./assets/fonts/Circe/Circe/Circe-ExtraBold.otf") format("opentype");
    font-weight: bold; }
@font-face {
    font-family: "CRC25";
    src: local("CRC25");
    src: url("./assets/fonts/Circe/Circe/CRC25.otf") format("opentype");
    font-weight: bold; }
@font-face {
    font-family: "CRC35";
    src: local("CRC35");
    src: url("./assets/fonts/Circe/Circe/CRC35.otf") format("opentype");
 }    // font-weight: bold
@font-face {
    font-family: "CRC55";
    src: local("CRC55");
    src: url("./assets/fonts/Circe/Circe/CRC55.otf") format("opentype");
    font-weight: bold; }
@font-face {
    font-family: "Inconsolata";
    src: local("Inconsolata");
    src: url("./assets/fonts/Inconsolata_Expanded/Inconsolata_Expanded-Bold.ttf") format("truetype");
    font-weight: regular; }

@import './sass/theme/import';

@import '~bootstrap/scss/bootstrap.scss';

@import './sass/mixins/import';
@import './sass/utilities/import';
@import './sass/components/import';
@import './sass/parts/import';
@import './sass/routes/import';
@import './sass/bootstrap/import';

.DiskSize .progress-bar {
    background-color: #9b9bb6;
    &:hover {
        background-color: $c-forth; } }

.account .FormSelect.FormSelectOptions {
    width: 330px; }

.PlayMedia {
    .icon-pause {
        display: none;
        cursor: pointer; }
    .icon-play {
        display: block;
        cursor: pointer; }
    &.playing {
        .icon-pause {
            display: block; }
        .icon-play {
            display: none; } } }
.cursor {
    cursor: pointer; }

.opacity-25 {
    opacity: 0.25; }

