.Songs__grid {
    display: flex;
    align-items: center;
    height: 45px;
    @include media-breakpoint-down(sm) {
        height: 80px; }
    &--active button {
        font-weight: bold;
        color: rgba(10, 10, 10, 0.88) !important; }
    .number {
        width: 100%;
        max-width: 4px;
        //margin-left: 10px
        white-space: nowrap; }
    .play {
        width: 20px;
        min-width: 20px; }
    .composition {
        // margin-left: 20px
        width: 100%;
        max-width: 200px;
        overflow: hidden;
        min-width: 125px;
        h4 {
            font-size: $fz-sm;
            color: $c-dark;
            font-weight: $fw-bold;
            margin: 0; } }
    .name {

        width: 100%;
        // max-width: 290px
        overflow: hidden; }
    .time {
        width: 75px;
        min-width: 75px; }
    .duration {
        width: 100%;
        max-width: 70px; }
    .temp {
        width: 100%;
        // max-width: 55px
        text-align: center; }

    .chill_out {
        width: 100%;
 }        // max-width: 70px

    .author {
        width: 100%;

 }        // max-width: 120px
    .performer {
        width: 100%;

 }        // max-width: 120px
    .album {
        width: 100%;

 }        // max-width: 75px
    .id {
        min-width: 55px; }
    .duration_video {
        width: 80%;
        margin-left: auto;
        max-width: 125px; }
    .composition_video {
        // margin-left: 40px
        // margin-left: 20px
        width: 100%;
        max-width: 200px;
        overflow: hidden;
        h4 {
            font-size: $fz-sm;
            color: $c-dark;
            font-weight: $fw-bold;
            margin: 0; } }
    .type_video {
        width: 80%;
        margin-left: auto;
        max-width: 100px; }
    .added_video {
        width: 80%;
        margin-left: auto;
        max-width: 100px; }
    .added {
        width: 100%;
        margin-left: auto;
        max-width: 100px; }

    .ended {
        width: 20px;
        min-width: 20px; }
    .open {
        width: 20px;
        min-width: 20px;
        margin-left: auto; }

    & > *:not(:last-child) {
        margin-right: $val-sm-1; } }
