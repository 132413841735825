@mixin lables-titles {
    font-family: $ff-title;
    font-size: $fz-sm;
    text-transform: uppercase;
    font-weight: $fw-normal;
    margin-bottom: $val-xs-1;
    // margin-bottom: $val-xs-1 - $val-mr
    color: $c-dark-lg;

    .subtitle {
        padding-top: 3px;
        text-transform: none;
        font-size: $fz-xs;
        font-style: italic; } }
