@include place-not-expanded {
    .Collapsible:not(.active) {
        & > *:not(.Collapsible__header) {
            display: none;
            @include media-breakpoint-down(lg) {
                display: block; } }
        & > .Collapsible__header {
            .Collapsible__desktop {
                margin-bottom: 0; }
            .Collapsible__notice {
                display: none; } } } }

.Collapsible {

    // Адаптив

    &.active {
        & > .Collapsible__header {
            .toggle-active svg {
                transform: rotate(180deg); } } }

    @include media-breakpoint-up(lg) {
        &__mobile {
            display: none; } }

    @include media-breakpoint-down(md) {
        &__desktop {
            display: none; } }

    // Элементы

    & > .Collapsible__header {

        .Collapsible__title {
            @include spp-font-title-2;
            cursor: pointer;
            .subtitle {
                display: inline-block;
                margin-left: $val-xs-1;
                font-weight: $fw-normal;
                text-transform: none;
                font-size: $fz-md;
                opacity: $o-md; }
            .badge {
                font-size: 0.8rem;
                position: relative;
                bottom: 12px;
                display: inline-block; } }

        .Collapsible__notice {
            font-family: $ff-title;
            font-weight: $fw-normal;
            color: $c-dark-lg;
            font-size: $fz-sm;

            @include media-breakpoint-up(mg) {
                margin-bottom: $val-sm; }

            @include media-breakpoint-down(xl) {
                margin-bottom: $val-sm; }

            @include media-breakpoint-down(md) {
                margin-bottom: $val-md-1; } }

        .Collapsible__desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .left {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-grow: 1; }
            .right {
                flex-grow: 100; }
            h2 {
                margin: 0;
                width: auto; }
            .control {
                display: flex;
                align-items: center;
                & > * {
                    margin-left: $val-sm-1; }
                .toggle-active {
                    display: none; } }

            @include media-breakpoint-up(mg) {
                margin-bottom: $val-sm;
 }                // margin-bottom: $val-md-1

            @include media-breakpoint-down(xl) {
                border-bottom: $bw-xs solid rgba($c-dark, $o-mr);
                margin-bottom: $val-sm;
                .left,
                .right {
                    padding-bottom: $val-sm-1; }
                .control {
                    .toggle-active {
                        display: inline-block; } }
                .right {
                    width: 100%;
                    .toggle-active {
                        display: none; } } }

            @include media-breakpoint-down(md) {
                border-bottom: $bw-xs solid rgba($c-dark, $o-mr);
                margin-bottom: $val-md-1; } }

        .Collapsible__mobile {
            .plus-mobile {
                margin-top: $val-xl-1;
                margin-bottom: $val-xl-1; }
            .elements {
                @include Collapsible-mod-font-sizes;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: $val-md-1;
                & > *:not(:last-child) {
                    margin-right: $val-sm-1; } } }

        .toggle-active svg {
            transition: .2s; } }

    &.Collapsible_embedded {
        & > .Collapsible__header {
            .Collapsible__title {
                font-weight: $fw-normal;
                font-size: $fz-md;
                .subtitle {
                    font-size: $fz-sm; } } } }

    &__Empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark-lg; } }
