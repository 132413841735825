.AdvertWhenSettingsCollapsible {

    @include media-desktop {
        .Collapsible__header {
            margin-bottom: $val-sm-2;

            .Collapsible__desktop {
                flex-wrap: nowrap; } } }

    @include media-tablet {
        .Collapsible__desktop {
            flex-wrap: nowrap;
            align-items: flex-start;
            .left {
                width: 100%; }
            .right {
                display: flex;
                justify-content: flex-end;
                width: auto; } } }

    @include media-mobile {
        .Collapsible__desktop {
            & > .left {
                display: none; }
            & > .SettingsPresets {
                width: 100%;
                margin-bottom: $val-xl-1; } } } }
