.FormSelectOptions {

    &__input {
        &_search {
            cursor: text !important; } }

    .FormSelect__content {

        .FormSelectOptions__item {
            font-family: $ff-title;
            font-weight: $fw-bold;
            font-size: $fz-sm;
            padding: 7px 4px;
            cursor: pointer;
            & > .subtitle {
                color: $c-dark-lg; } }

        .FormSelectOptions__empty {
            font-family: $ff-title;
            font-weight: $fw-bold;
            font-size: $fz-sm;
            padding: 7px 4px;
            cursor: default;
            color: $c-dark-lg; }

        &:not(.FormSelect__content_multiple) {
            .FormSelectOptions__item {
                color: $c-dark;
                &:hover {
                    color: rgba($c-dark, $o-xl); }
                &.selected {
                    color: rgba($c-dark, $o-xl);
                    background-color: $c-light-md; }
                &.underlined {
                    border: solid 0.3em transparent;
                    border-bottom-color: #ff3347; } } }

        &_multiple {
            .FormSelectOptions__item {
                color: rgba($c-dark, $o-xl);
                &:hover,
                &.selected:not(.multiple) {
                    color: $c-dark; }
                &.multiple {
                    margin-bottom: $val-mr; }

                .delete {
                    margin-left: $val-mr;
                    transform: translateY(-1px);
                    &:hover {
                        opacity: $o-xl; } } } }

        &_width-reset {
            .FormSelectOptions__item {
                &:not(.multiple):first-child {
                    padding-top: 0; } } }

        &_search {
            top: 75px !important; } } }
