.FormSwitch {

    &__button {
        display: inline-block;
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        cursor: pointer;
        &.active {
            color: $c-prim; }
        &:not(.active) {
            color: $c-dark-lg; }

        @include media-tablet-and-up {
            &:not(:last-child) {
                &:after {
                    content: "/";
                    display: inline-block;
                    font-weight: $fw-normal;
                    color: $c-dark-md;
                    margin: {
                        right: $val-xs;
                        left: $val-xs; } } } }

        @include media-mobile {
            padding: $val-xs-1;
            text-align: center;

            border-radius: $br-mg;
            border-width: $bw-xs;
            border-style: solid;
            &.active {
                border-color: $c-prim; }
            &:not(.active) {
                border-color: $c-dark-lg; }

            width: calc(50% - #{$val-sm-1/2});
            &:first-child {
                margin-right: $val-sm-1; } } } }
