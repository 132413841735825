.Tracks__grid {
    display: flex;
    align-items: center;

    .id {
        width: 50px;
        min-width: 50px;
        white-space: nowrap; }
    .play {
        width: 20px;
        min-width: 20px; }
    .name {
        width: 100%;
        max-width: 290px;
        overflow: hidden; }
    .time {
        width: 75px;
        min-width: 75px; }
    .ended {
        width: 20px;
        min-width: 20px; }
    .open {
        width: 20px;
        min-width: 20px;
        margin-left: auto {
            min-width: 20px; } }
    .pri {
        width: 30px;
        margin-left: auto;
        min-width: 30px; }

    .priText {
        width: 35px;
        height: 35px;
        color: rgba(10, 10, 10, 0.48);
        border-radius: 40px;
        font-size: 13px;
        border: 2px solid rgba(10, 10, 10, 0.48);
        display: flex;
        justify-content: center;
        align-items: center; }


    & > *:not(:last-child) {
        margin-right: $val-sm-1; } }
