@mixin media-desktop {
    @include media-breakpoint-up(mg) {
        @content; } }

@mixin media-tablet-and-up {
    @media (min-width: $bp-md) {
        @content; } }

@mixin media-tablet {
    @media (min-width: $bp-md) and (max-width: $bp-mg) {
        @content; } }

@mixin media-tablet-and-down {
    @media (max-width: $bp-mg) {
        @content; } }

@mixin media-mobile {
    @include media-breakpoint-down(md) {
        @content; } }
