.SettingsPeriods {
    display: flex;
    align-items: center;

    .FormSelect {
        @include media-tablet-and-up {
            max-width: 250px; } }

    &__select {
        margin-right: $val-sm-2;
        @include media-mobile {
            flex-grow: 1; } } }
