.Settings {

    // warning

    &__warning {
        font-family: $ff-text;
        font-weight: $fw-normal;
        font-size: $fz-sm;
        color: $c-light;
        padding: $val-xs-1;
        background-color: rgba($c-forth, $o-md);
        border: $bw-xs solid $c-light;
        border-radius: $br-md;
        margin-bottom: $val-md-1; }

    // header

    &__header {
        margin-bottom: $val-md-1;
        h3 {
            margin: 0; } }

    &__element-type-title {
        position: relative;
        margin-bottom: $val-xs-1;
        font-family: $ff-title;
        font-size: $fz-xs;
        font-weight: $fw-normal;
        text-transform: uppercase;
        color: $c-dark-lg;
        &::after {
            @include divider;
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: calc(50% - #{$bw-xs}); }
        div {
            position: relative;
            z-index: 2;
            display: inline-block;
            background-color: $c-light-md;
            padding-right: $val-mr; } } }

@include place-expanded-desktop {
    .Settings__hidden {
        display: none; }

    .Settings {

        .Settings__groups {
            display: flex;
            & > * {
                flex-grow: 1; }

            .expanded-left,
            .expanded-right {
                max-width: 540px; }

            .expanded-left {
                margin-right: $val-xl-1;
 }                // max-width: 720px

            .expanded-all {
                display: flex;
                flex-wrap: wrap; } } } }
