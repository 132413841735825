.Spinner {
    & > .scale {
        transform: scale(.8); }
    &__text {
        margin-top: 15px;
        display: block;
        color: #e8e8e8;
        background: #6a6a6a;
        padding: 5px 8px;
        border-radius: 5px;
        max-width: 350px; } }
