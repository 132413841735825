.SettingsTags {

    &__header {
        display: flex;
        min-width: 220px;
        align-items: flex-end;
        margin-bottom: $val-sm;
        @include media-tablet-and-up {
            width: 45%; }

        & > *:not(:last-child) {
            margin-right: $val-xs-1; }

        .FormInputText {
            flex-grow: 1; }

        .icon-add {
            margin-bottom: 4px; } } }

@include place-expanded-desktop {
    .SettingsTags {
        .FormInputText {
            max-width: 300px; } } }
