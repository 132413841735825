@mixin content-style {
    @include box-shadow($bs-lg rgba($c-forth, $o-mr));
    padding: $val-sm-2;
    border: $bw-xs solid $c-light-sm;
    border-radius: $br-md;
    background-color: $c-light;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

@mixin selected-style($fz, $selected-padding, $date-width, $date-margin) {
    $p: $selected-padding;
    $offset: $date-margin + ($date-width/2);

    position: relative;
    z-index: 1;
    font-weight: $fw-bold;
    color: $c-dark;
    font-size: $fz;

    span {
        cursor: pointer;
        &:hover:not(.selected):not(.disabled) {
            color: rgba($c-dark, $o-xl); } }

    .disabled {
        color: $c-dark-lg;
        pointer-events: none; }

    .selected {
        color: $c-prim;
        &::after {
            left: -$p;
            right: -$p;
            border-top-right-radius: 500px;
            border-bottom-right-radius: 500px;
            border-top-left-radius: 500px;
            border-bottom-left-radius: 500px; } }

    .selected,
    .selected-middle {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: -$p;
            bottom: -$p;
            z-index: -1;
            background-color: $c-light-lg; } }

    .selected-middle {
        &:not(:first-child):not(:last-child):not(.selected-middle-first):not(.selected-middle-last) {
            &::after {
                left: -$offset;
                right: -$offset; } }
        &.selected-middle-first::after,
        &:first-child::after {
            left: -$p;
            right: -$offset;
            border-top-left-radius: 500px;
            border-bottom-left-radius: 500px; }
        &.selected-middle-last,
        &:last-child {
            &::after {
                right: -$p;
                border-top-right-radius: 500px;
                border-bottom-right-radius: 500px; }
            &:not(.selected-middle-first):not(:first-child)::after, {
                left: -$offset; } } }

    .selected + .selected {
        &::after {
            left: -$offset;
            border-top-right-radius: 500px;
            border-bottom-right-radius: 500px; } } }
