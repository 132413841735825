.Templates {
    margin-top: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
    &_spinner {
        margin-top: 10%; }
    &_one {
        gap: 20px;
        display: flex;
        flex: 1;
        flex-direction: column; }
    &_two {
        gap: 20px;
        display: flex;
        flex: 1;
        flex-direction: column; }
    &_elements_row {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 16px;
        // justify-content: space-between
        align-items: end;
        & > *:first-child {
            flex: 1; } } }
