.HelpMenuList {
    .List__content {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .selectable-item {
            margin: 0 !important; }
        .selected {
            // margin-top: 0 !important
            // margin-bottom: 0 !important
            border-radius: 4px !important;
            // margin: 0 !important
            border: none !important; } }
    .HelpMenuItem {
        font-weight: 500;
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        padding: 10px;
        background: white;
        cursor: pointer;
        border-radius: 4px; } }
.HelpContent-parent {
    padding-left: 9px;
    padding-right: 9px;
    overflow-y: scroll;
    max-height: calc(100vh - 220px);
    scrollbar-width: thin;
    &::-webkit-scrollbar-thumb {
        background-color: $c-dark-sm;
        border-radius: 500px; }
    &::-webkit-scrollbar-track-piece {
        background: $c-light;
        border-radius: 500px; }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0; }
    &::-webkit-scrollbar {
        height: 4px;
        width: 4px; }
    .HelpContent {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-family: "CRC55"; }
    img {
        width: 100% !important;
        border: 4px solid white;
        border-radius: 4px;
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08); } }
.Callback {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    button {
        width: 100%; }
    &__phone_inputs {
        margin-top: 30px;
        width: 100%;
        flex-wrap: wrap;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 40px;
        div {
            flex: 1; } }
    &__question_inputs {
        margin-top: 30px;
        width: 100%;
        flex-wrap: wrap;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 40px;
        div {
            flex: 1; } } }

.CollapsibleCardHelp {
    @include box-shadow($bs-md rgba($c-forth, $o-mr));
    // padding: $val-xs-1
    padding-left: 4px;
    padding-right: 4px;
    background-color: $c-light;
    overflow: hidden;
    margin: {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important; }

    &:last-child {
        margin-bottom: 0 !important; }

    &.selected &__content {
        & > .ScrollContainer > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content > * {
            margin-bottom: $bw-sm;
            &:first-child {
                margin-top: 0; }
            &:not(.selected) {
                background-color: $c-light; }
            &.selected + .selected {
                margin-top: -$bw-sm; } } }

    &.opened &__header .spp-svg {
        transform: rotate(180deg); }
    &:not(.opened) &__content {
        display: none; }

    &__header {

        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__title {
        width: 100%;
        cursor: pointer;
        font-family: $ff-title;
        font-weight: $fw-bold; }

    &__open .spp-svg {
        transition: .2s;
        cursor: pointer; }

    &__Empty {
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        color: $c-dark-lg; }

    // Адаптив

    @include media-breakpoint-up(lg) {
        border-radius: $br-md;
        &:not(:last-child) {
 }            // margin-bottom: $bw-sm !important

        &.selected {
            border-color: $c-light; }
        &:not(.selected) {
            border: $bw-xs solid $c-light-sm; }

        &__title {
            font-size: $fz-sm;
            color: $c-dark-lg; }
        &__content {
            margin-top: $val-xs-1;
            margin-right: 6px; }

        &__content > .ScrollContainer > .ScrollContainer__more {
            margin-bottom: $val-lg; } }

    @include media-breakpoint-down(md) {
        border-radius: $br-lg-2;
        // padding: ($val-md-2 - $bw-md) ($val-sm-2 - $bw-md)
        padding-bottom: 0;

        &:not(:last-child) {
 }            // margin-bottom: $val-mr !important

        &.selected {
            border: $bw-md solid $c-light; }
        &:not(.selected) {
            border: $bw-md solid $c-light; }

        &__header {
 }            // margin-bottom: ($val-md-2 - $bw-md)
        &__title {
            font-size: $fz-sm;
            color: $c-dark; }
        &__open {
            svg {
                height: $val-sm-1 !important; } }

        &__content > .ScrollContainer {
            & > .ScrollContainer__background {
                margin-bottom: $val-lg; }
            & > .ScrollContainer__more {
                margin-bottom: ($val-lg - $bw-md); } } } }
.Help__header {
    height: 50px; }
.Help__grid {
 }    // height: 30px
