.Lines {

    &__control {
        @include Collapsible-child-control-pb;
        @include Collapsible-mod-font-sizes;
        @include media-breakpoint-up(lg) {
            padding-left: $val-xs + $bw-md;
            padding-right: $val-xs + $bw-md;
            & > .Lines__grid > .buttons {
                display: none !important; } }
        @include media-breakpoint-down(md) {
            padding-top: 0;
            border-top: 0;
            padding-left: $val-xs-1 + $bw-md;
            padding-right: $val-xs-1 + $bw-md;
            & > .Lines__grid {
                flex-wrap: wrap;
                & > .message {
                    order: -1;
                    margin-bottom: $val-xl-2; } } }

        & > .Lines__grid {
            & > .title {
                white-space: nowrap;
                width: min-content; }
            & > .message {
                width: 300%; }
            & > .buttons {
                display: flex;
                margin-left: auto;
                width: min-content;
                & > *:not(:last-child) {
                    margin-right: $val-sm-1; } } } }

    &__grid {
        display: flex;
        align-items: center;
        & > *:first-child {
            width: 50px;
            min-width: 41px; }
        & > *:not(:first-child) {
            width: 100%; }
        & > *:not(:last-child) {
            margin-right: $val-sm-1; } }

    // &__list
    //     .Line
    //         margin: 0 $bw-md
    //         &.selected
    //             margin-top: $bw-md
    //             margin-bottom: $bw-md
    //         &:first-child,
    //         &:last-child
 }    //             margin-bottom: $bw-md
