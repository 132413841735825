.SettingsFeatures {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1000px;
    &__scroll {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-height: 36vh;
        overflow-y: scroll;
        padding: 6px;
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
            background-color: $c-dark-sm;
            border-radius: 500px; }
        &::-webkit-scrollbar-track-piece {
            background: $c-light;
            border-radius: 500px; }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0; }
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px; } }
    &__editrole {
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        background-color: #ffffff;
        border-radius: 4px;
        padding-bottom: 14px;
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // margin-bottom: 12px
        gap: 24px;
        & > *:first-child {
            flex: 1; }
        .eye {
            cursor: pointer;
            z-index: 2 !important;
            svg {

                height: 14px !important;
                & > * {
                    fill: #B7B7B7; } } }
        .spp-svg {
            z-index: 2 !important; }
        .eye-shown {
            cursor: pointer;
            z-index: 1000;
            svg {
                & > * {
                    fill: black; } } } }
    &__description_parent {
        box-shadow: 0 10px 16px rgba(89, 89, 128, 0.08);
        background-color: #ffffff;
        border-radius: 4px;
        padding-bottom: 24px;
        padding-top: 24px;
        padding-left: 20px;
        padding-right: 20px; }
    &__label {
        color: rgba(10, 10, 10, 0.48);
        font-size: 0.813rem;
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 20px; } }


.SettingsFeatures .FormInputTextarea textarea {
            min-height: 50px;
            resize: none; }
