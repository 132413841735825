@mixin FormSelect__content-opened {
    @include box-shadow($bs-lg rgba($c-forth, $o-mr));
    transition: .2s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-index-elements;
    border: $bw-xs solid $c-light-sm;
    border-radius: $br-md;
    background-color: $c-light;
    padding: $val-mr $val-xs-1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

@mixin FormSelect__content-notopened {
    transform: translateY($val-sm);
    opacity: 0;
    pointer-events: none; }

@mixin FormSelect__content {
    @include FormSelect__content-opened;
    &:not(.opened) {
        @include FormSelect__content-notopened; } }
