@mixin spp-container_m {
    max-width: $bp-mg - ($container-offset-mg*2);
    margin-left: auto;
    margin-right: auto;
    @each $size, $key in $container-offset-sizes {
        @include media-breakpoint-only($size) {
            width: calc(100% - #{map-get($container-offset-sizes, $size)*2}); } } }

@mixin spp-container_p {
    max-width: $bp-mg;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    @each $size, $key in $container-offset-sizes {
        @include media-breakpoint-only($size) {
            padding-right: map-get($container-offset-sizes, $size);
            padding-left: map-get($container-offset-sizes, $size); } } }
