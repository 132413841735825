.FormSelectMinutesOfHour {

    &__blocks {
        form {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding-top: $val-xs;
            padding-bottom: $val-xs; } } }

@include media-breakpoint-down(md) {
    .mobile-modal:not(.mobile-modal_disabled) .mobile-modal__content > .FormSelectMinutesOfHour {

        .FormSelectMinutesOfHour__blocks {
            justify-content: center;
            margin-top: -$val-lg -$val-sm 0;
            & > * {
                width: 100%;
                padding-top: $val-lg;
                &:not(:last-child) {
                    border-right: $bw-xs solid $c-light-sm; } }

            .FormSelectMinutesOfHourPeriod__title {
                font-size: $fz-md; }

            .FormSelectMinutesOfHourPeriod__content {
                margin: $val-lg-1 0 $val-mg-3; }

            .FormSelectMinutesOfHourPeriod__periods {
                width: $val-mg-3;

                .period {
                    &:not(:last-child) {
                        margin-bottom: $val-lg; }

                    &_1, &_2, &_4, &_5 {
                        font-size: $fz-lg;
                        height: 22px; }
                    &_3 {
                        font-size: $fz-xl-1;
                        height: 40px;
                        width: $val-mg-3; } } }

            .FormSelectMinutesOfHourPeriod__buttons {
                svg {
                    height: $val-sm-1; } } } } }
