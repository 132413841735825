@mixin search-size-normal {
    &:not(.active):not(:focus) {
        .SvgSearch {
            display: none; } } }

@mixin search-size-compact {
    &:not(.active):not(:focus) {
        input,
        input + .spp-svg {
                display: none !important; } } }
