.SettingsClips {

    &.collapsed &__collapse {
        margin-bottom: $val-md-1; }

    &__header {
        @include lables-titles;
        font-size: $fz-xs;
        padding-left: ($bw-md + $val-mr);
        padding-right: ($bw-md + $val-mr);
        margin-bottom: $val-xs-1; }

    &__table {
        @include box-shadow($bs-sm rgba($c-forth, $o-mr));
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-normal;
        color: $c-dark;
        margin-top: $val-xs;
        padding-top: $bw-sm;
        overflow: hidden;
        border-radius: $bw-md;
        background-color: $c-light;
        & > .item {
            @include selectable-item;
            padding: $val-mr;
            cursor: pointer;
            &:nth-child(2n):not(.selected) {
                background-color: $c-light-md; } }
        // .time,
        // .date
 }        //     text-align: center

    &__schedule {
        transition: .2s; }
    &:not(.collapsed) &__schedule {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        overflow: hidden;
        height: 0; }

    &__grid {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .subtitle {
            width: 100%;
            font-style: italic;
            font-size: $fz-xs; }
        & > * {
            flex-grow: 1; }
        .title {
            font-weight: $fw-bold;
            line-height: $val-sm-2;
            padding-left: ($bw-md + $val-mr);
            padding-right: ($bw-md + $val-mr); }
        .date {
            width: 155px; }
        .time {
            width: 90px; }
        .channel {
            width: 70px;
            text-align: center; }
        .delete {
            width: 32px; } } }
