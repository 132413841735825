.FormCycleTime {
    position: relative;

    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: $o-md; }

    &__content {
        @include media-tablet-and-up {
            @include FormSelect__content;
            min-width: 170px; }

        @include media-mobile {
            min-width: 120px; } }

    &__times {
        display: flex;
        justify-content: space-evenly; }

    &__input {
        input {
            cursor: pointer; } }


    @include media-mobile {
        .FormCycleTime__times {
            justify-content: center;
            margin-top: -$val-lg -$val-sm 0;
            & > * {
                width: 100%;
                padding-top: $val-lg;
                &:not(:last-child) {
                    border-right: $bw-xs solid $c-light-sm; } } } } }
