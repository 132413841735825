.form-group {
    position: relative;

    .form-label {
        @include lables-titles; }

    input.form-control,
    textarea.form-control {
        position: relative;
        z-index: 2;
        box-shadow: none !important;
        height: auto;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        font-family: $ff-title;
        border-radius: 0 !important;
        padding: $val-mr 0 !important;
        background-color: transparent !important;
        line-height: 1;
        border: {
            top-width: 0;
            left-width: 0;
            right-width: 0;
            bottom-width: $bw-xs; } }

    &.icon {
        div.form-control,
        input.form-control,
        textarea.form-control {
            padding-right: $val-sm !important; } }

    &:not(.italic) {
        div.form-control,
        input.form-control,
        textarea.form-control {
            font-weight: $fw-bold; } }

    &.italic {
        div.form-control,
        input.form-control,
        textarea.form-control {
            font-weight: $fw-normal;
            font-style: italic; } }

    & > div > .spp-svg,
    & > .spp-svg {
        position: absolute;
        right: 0;
        bottom: $val-mr;
        z-index: 1;
        &:not([class*='spp-svg-size']) {
            @include set-svg-size(sm1); } }

    //
    // Цвета
    //

    &:not(.light) {

        .form-label {
            color: $c-dark-lg; }

        div.form-control,
        input.form-control,
        textarea.form-control {

            &.disabled, &:disabled {
                &, & + .spp-svg {
                    @include form-disabled; } }

            &:not(:hover):not(.disabled):not(:disabled):not(:focus):not(.active):not(:active) {
                @include input-placeholder-color( $c-dark-lg );
                @include input-value-color( rgba($c-dark, $o-xl) );
                border-bottom-color: $c-dark; }

            &:hover:not(:focus):not(.active):not(:active) {
                @include input-placeholder-color( $c-dark-lg );
                @include input-value-color( rgba($c-dark, $o-xl) );
                border-bottom-color: rgba($c-dark, $o-xl);
                & + .spp-svg {
                    opacity: $o-xl; } }

            &:focus, &.active, &:active {
                @include input-placeholder-color( rgba($c-dark, $o-xl) );
                @include input-value-color( rgba($c-dark, $o-xl) );
                border-bottom-color: rgba($c-dark, $o-xl); } }

        *:not(.ButtonCircle) .spp-svg:not([class*='spp-svg-color']) {
            @include set-svg-color('dark'); } }

    &.light {

        .form-label {
            color: rgba($c-light, $o-md); }

        div.form-control,
        input.form-control,
        textarea.form-control {

            &.disabled, &:disabled {
                &, & + .spp-svg {
                    @include form-disabled; } }

            &:not(:hover):not(.disabled):not(:disabled):not(:focus):not(.active):not(:active) {
                @include input-placeholder-color( rgba($c-light, $o-md) );
                @include input-value-color( $c-light );
                border-bottom-color: $c-light; }

            &:hover:not(:focus):not(.active):not(:active) {
                @include input-placeholder-color( rgba($c-light, $o-md) );
                @include input-value-color( $c-light );
                border-bottom-color: rgba($c-light, $o-xl);
                & + .spp-svg {
                    opacity: $o-xl; } }

            &:focus, &.active, &:active {
                @include input-placeholder-color( $c-light );
                @include input-value-color( $c-light );
                border-bottom-color: $c-light; } }

        & > div > .spp-svg:not([class*='spp-svg-color']),
        & > .spp-svg:not([class*='spp-svg-color']) {
            @include set-svg-color('light'); } } }

fieldset:disabled {
    .form-label,
    .form-control {
        @include form-disabled; } }
