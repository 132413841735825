.Tags {

    // Отступы

    &__show {
        margin-top: $val-sm-1; }

    // Элементы

    &__items {
        margin: {
            right: -($val-mr-1);
            bottom: -($val-mr-1); }
        & > * {
            display: inline-block;
            margin: {
                right: $val-mr-1;
                bottom: $val-mr-1; } }

        .item {
            font-family: $ff-title;
            font-weight: $fw-bold;
            font-size: $fz-xs;
            line-height: 1;
            color: $c-dark;
            padding: $val-mr $val-xs-1;
            border-radius: $br-mg;
            border: $bw-xs solid $c-third;
            background-color: $c-light;
            text-align: center;
            white-space: nowrap;
            .item-delete {
                margin-left: $val-mr;
                cursor: pointer;
                opacity: $o-lg;
                &:hover {
                    opacity: 1; } } } }

    &_chosen {
        .item:hover {
            cursor: pointer;
            color: rgba($c-dark, $o-xl); } }

    &__empty {
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        color: $c-dark-lg; }

    &__show {
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-sm;
        color: $c-dark;
        text-align: center;
        .show-wrap {
            display: inline-block;
            text-align: center;
            opacity: $o-lg;
            cursor: pointer;
            &:hover {
                opacity: $o-md; }
            .spp-svg {
                margin-right: $val-mr; } } }

    // Показать всё

    &.showed &__show .show {
        display: none; }
    &:not(.showed) &__show .hide {
        display: none; }

    &:not(.showed) &__items > *:nth-child(n+10) {
        display: none; } }
