.CollapsibleMobileControl {
    @include box-shadow(0 0 16px rgba($c-forth, $o-mr));
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .2s;
    display: flex;
    // height: 100px
    border-radius: $br-xl-1 $br-xl-1 0 0;
    background-color: $c-light;
    padding: $val-sm-2;

    &:not(.active) {
        transform: translateY(110%);
        opacity: 0; }

    .ButtonCircle {
        padding: $val-md-1;
        width: ($val-md-1*2)+$val-md-3;
        min-width: ($val-md-1*2)+$val-md-3;
        height: ($val-md-1*2)+$val-md-3;
        svg {
            height: $val-md-3; } }

    .ButtonWhite {
        height: ($val-md-1*2)+$val-md-3;
        @include media-breakpoint-down(sm) {
            .spp-svg {
                margin-right: 0; }
            span {
                display: none; } } }

    & > * {
        color: $c-dark;
        &:not(:last-child) {
            margin-right: $val-sm-1; }
        &:not(:hover):not(.active):not(:active) {
            @include box-shadow(0 0 16px rgba($c-forth, $o-mr) !important); } } }
