.ModalMediaplan {

    .Modal__header {
        margin-bottom: 15px; }

    &__form {
        display: flex;
        margin-bottom: 25px; }

    &__track-name {
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-xl;
        color: $c-dark; }

    &__empty {
        font-family: $ff-title;
        font-weight: $fw-normal;
        font-size: $fz-md;
        color: $c-dark; }

    .ModalMediaplanObject {

        &__type {
            margin-bottom: $val-xs; }

        &__item, &__header2 {
            margin-bottom: $val-xs; }

        &__title {
            font-family: $ff-title;
            font-weight: $fw-bold;
            font-size: $fz-md;
            color: $c-dark;
            margin-bottom: $val-md-1; }

        &__header {
            padding: 0 ($bw-md + $val-sm-1) $val-mr;
            .days,
            .schedule {
                display: flex;
                align-items: center; } }

        &__rows {
            @include box-shadow($bs-md rgba($c-forth, $o-mr));
            padding: 0 $bw-md;
            background-color: $c-light;
            border: $bw-xs solid $c-light-sm;
            border-radius: $br-md;
            .rows {
                border-bottom: $bw-xs solid $c-light-sm; }
            .finnaly {
                padding: $val-sm-1;
                font-family: $ff-title;
                font-weight: $fw-bold;
                font-size: $fz-sm;
                color: $c-dark-lg;
                text-align: right;
                span {
                    display: inline-block;
                    margin-left: $val-sm-1;
                    color: $c-dark; } }

            .ModalMediaplanObject__item {
                padding: $val-sm-1;
                font-family: $ff-title;
                font-weight: $fw-normal;
                font-size: $fz-sm;
                color: $c-dark;
                &:nth-child(2n) {
                    background-color: $c-light-md; } } } }

    .ModalMediaplanObject + .ModalMediaplanObject {
        margin-top: $val-lg-1; } }

@include media-breakpoint-up(lg) {
    .Modal_type_mediaplan .ScrollContainer__wrap {
        max-height: 50vh !important; }

    .ModalMediaplan__track-name {
        display: inline; }

    .ModalMediaplanObject__type {
        display: none; }

    .ModalMediaplanObject__header {
        .mob {
            display: none; } }

    .ModalMediaplanObject__grid {
        display: flex;
        & > *:not(:last-child) {
            margin-right: $val-sm-2; }
        .start {
            min-width: 100px; }
        .end {
            min-width: 120px; }
        .days {
            min-width: 85px; }
        .daymask {
            min-width: 170px; }
        .schedule {
            width: 300px; }
        .track {
            width: 200px; } } }

@include media-breakpoint-down(md) {

    .ModalMediaplanObject__header {
        .des {
            display: none; } }

    .ModalMediaplanObject__grid {
        display: flex;
        & > *:not(:last-child) {
            margin-right: $val-sm-2; }
        .start {
            min-width: 75px; }
        .end {
            min-width: 75px; }
        .days {
            min-width: 35px; } } }


.PlayMedia .schedule {
    font-family: monospace; }

