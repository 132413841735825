@mixin v-medialist-extend {
    .v-mobile,
    .v-simple {
        display: none !important; }

    .id {
        width: 40px;
        min-width: 40px; }

    .time {
        width: 80px;
        min-width: 80px;
        &::before {
            display: none; } }

    .advert {
        width: 90px;
        min-width: 90px; }

    .double {
        &__item {
            .right {
                width: 190px;
                //min-width: 190px
 } } } }                //max-width: 190px

@mixin v-medialist-simple {
    .v-mobile,
    .v-extend {
        display: none !important; }

    .id {
        width: 40px;
        min-width: 40px; }

    .time {
        width: 45px;
        min-width: 45px; }

    .double {
        &__item {
            .right {
                width: 100px;
                min-width: 70px; } } }

    .select-all {
        margin-top: $val-xs-1;
        margin-left: $val-xs-1; } }

@mixin v-medialist-mobile {
    .v-mobile-hidden {
        display: none !important; }

    .select-all {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: $val-sm-2;
        font-size: $fz-xl; }

    .ObjectScheduleGroupSmartMediaList__header {
        &.ObjectScheduleGroupSmartMediaList__grid {
            justify-content: space-between; } }

    .ObjectScheduleGroupSmartMediaList__item {
        flex-wrap: wrap;
        align-items: start;
        .id {
            order: 1; }
        .double {
            order: 2; }
        .time {
            order: 3; }
        .far {
            order: 4; }

        .id {
            width: 40px;
            min-width: 40px;
            margin-right: $val-sm;
            color: $c-dark-lg; }

        &.without-ads {
            .id {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: calc(100% + #{$val-sm});
                    transform: translateY(-50%);
                    @include ads-circle; } } }

        .time {
            margin-left: auto;
            margin-right: 0;
            &::before {
                display: none; } }

        .double {
            @include divider-bb;
            flex-wrap: nowrap;
            margin-right: $val-xs-1;
            padding-bottom: ($val-sm - 1px);
            margin-bottom: $val-sm-1;
            & > .double__item {
                width: 120px;
                max-width: 120px;
                margin-bottom: 0;
                &:first-child {
                    margin-right: $val-xs-1; } } }

        .far {
            margin-left: $val-sm + 60px; } }

    .List__item {
        position: relative;
        .List__item-buttons {
            position: absolute;
            right: $val-mr;
            bottom: $val-sm-2; } } }
