@mixin selectable-item {
    margin-top: 0;
    margin-bottom: 0;

    // Отступ у последнего в списке
    &:last-child {
        margin-bottom: $bw-md; }

    // Отступ у первого в списке
    &_m-top:first-child:not(.selected) {
        margin-top: $bw-md; }
    &_m-top:last-child:not(.selected) {
        margin-bottom: 0; }

    &.selected {
        background-color: $c-light-lg !important; }

    &.highlighted:not(.selected) {
        background-color: $c-light-xl; }

    &.selected &__color:not([style]) {
        color: $c-dark-lg !important; }

    @include media-breakpoint-up(lg) {
        &:not(.selected) {
            margin-left: $bw-md;
            margin-right: $bw-md; }
        &.selected {
            border-radius: $br-md;
            margin: $bw-sm;
            border: $bw-sm solid $c-light-lg; }
        &.highlighted:not(.selected) {
            border-radius: $br-md;
            margin-bottom: $bw-sm;
            border: $bw-sm solid $c-light-mg; } }

    @include media-breakpoint-down(md) {
        margin-left: $bw-md;
        margin-right: $bw-md;
        &.selected {
            border-radius: $br-lg-2;
            margin-top: $bw-md;
            margin-bottom: $bw-md; }
        &.highlighted:not(.selected) {
            border-radius: $br-lg-2;
            margin-top: $bw-md;
            margin-bottom: $bw-md; } } }
