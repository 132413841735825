.AdvertsReport_collapsible {
    margin-top: -64px; }
.AdvertsReport {
    .ScrollContainer__content {
 }        // padding: 2px 4px

    &.hide-ended {
        .AdvertReport.clip-ended {
            display: none; } }

    &__control {
        @include Collapsible-child-control-pb;
        @include Collapsible-mod-font-sizes;
        @include media-breakpoint-down(sm) {
            padding-bottom: 20px; }
        .AdvertsReport__grid {
            @include media-breakpoint-down(sm) {
                height: 20px; } }
        .name {
 }            // min-width: 150px

        @include media-breakpoint-down(sm) {
            .last {
                white-space: nowrap; } }

        @include media-breakpoint-up(xl) {
            padding-right: ($val-xs-1*2);
            padding-left: $val-xs-1; }

        @include media-breakpoint-down(lg) {
            padding-right: 28px;
            padding-left: $val-sm-1; } }

    // Костыль, исправляет проблемы с отступами
    .List__item:last-child .Track {
        margin-bottom: $bw-md !important; }
    .List__item:first-child .Track {
        margin-top: $bw-md !important; }
    .List__item:not(:last-child) .Track:not(.selected) {
        margin-bottom: 0 !important; }
    .List__item:not(:first-child) .Track:not(.selected) {
        margin-top: 0 !important; } }
