.ModalAdvertCreate {

    @include media-breakpoint-up(lg) {
        &__grid {
            margin-bottom: -$val-md-1;
            & > * {
                width: 100%;
                margin-bottom: $val-md-1; } }

        .Uploader-title {
            display: none; }
        .Uploader {
            margin-bottom: $val-lg-1; }

        .FormInputText__comment {
            display: none; } }

    @include media-breakpoint-down(md) {
        .ModalAdvertDoubleGrid__left {
            display: flex;
            flex-wrap: wrap;
            & > * {
                width: 100%; }

            .ModalAdvertDoubleGrid__title.Uploader-title {
                order: 1; }
            .Uploader {
                order: 2; }
            .ModalAdvertDoubleGrid__title:not(.Uploader-title) {
                order: 3; }
            .ModalAdvertCreate__grid {
                order: 4; } }

        &__grid {
            & > *:not(:last-child) {
                margin-bottom: $val-xl-1; } }

        .Uploader {
            margin-bottom: $val-mg-1; } } }
