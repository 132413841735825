.MobileControls {
    @include box-shadow(0 0 16px rgba($c-forth, $o-mr));
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
    transition: .2s;
    display: flex;
    border-radius: $br-xl-1 $br-xl-1 0 0;
    background-color: $c-light;
    padding: $val-sm-2;

    &:not(.active) {
        transform: translateY(110%);
        opacity: 0; } }
