.SlideMenu {
    position: relative;

    &__wrap {
        overflow: hidden; }

    &__scroll {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 20px;
        margin-bottom: -20px; }

    &__padding {
 }        // padding: 0 24px

    &__menu {
        position: relative;
        display: flex;
        font-family: $ff-title;
        font-weight: $fw-bold;
        font-size: $fz-mg-1;
        text-transform: uppercase;
        white-space: nowrap;
        a {
            text-decoration: none; }
        .item {
            transition: .2s;
            color: $c-dark;
            white-space: nowrap;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: $val-xl-1; }
            &:not(.active) {
                color: $c-dark-sm; } } }

    &__line {
        transition: .2s;
        position: absolute;
        height: $bw-sm;
        left: 0;
        right: 0;
        bottom: -($val-xs + $bw-sm);
        border-radius: 500px;
        background-color: $c-dark-xs;
        overflow: hidden;
        .slide {
            $w: $val-xl-1;
            transition: .5s;
            position: absolute;
            top: 0;
            bottom: 0;
            width: $w;
            border-radius: 500px;
            background-color: $c-prim;
            &.start {
                left: 0; }
            &.center {
                left: calc(50% - #{$w/2}); }
            &.end {
                left: calc(100% - #{$w}); } } } }
