.ObjectBackgroundGroupBlocksList {

    &__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .time {
            font-family: $ff-title;
            font-weight: $fw-normal;
            font-size: $fz-sm;
            color: $c-dark; }

        @include media-mobile {
            align-items: center;
            justify-content: start;

            .time,
            .StringDays {
                margin-right: $val-sm; } } } }

