.ObjectsMenu {
    padding-top: $val-mr;
    .nav {
        flex-wrap: nowrap;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $fz-md;
            font-weight: $fw-bold;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            cursor: pointer;
            border-radius: $br-md $br-md 0 0;
            padding: $val-xs $val-sm ($val-xs + $val-mr);

            &:not(:hover):not(.active):not(:active) {
                color: $c-dark-lg; }

            &:hover:not(.active):not(:active) {
                color: $c-dark-md; }

            &.active, &:active {
                color: $c-prim;
                background-color: $c-light-md; } } } }

