.ScrollContainer__more {
    display: block;
    margin-top: 0;
    cursor: pointer;
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $ff-title;
        font-size: $fz-xl;
        font-weight: $fw-bold;
        color: $c-dark-lg;
        span {
            font-weight: $fw-normal;
            display: block;
            margin-left: $val-mr;
            i {
                margin-right: $val-sm;
                margin-top: -2px; } } } }

.ScrollContainer {

    &_white > &__background {
        &::after {
            content: ''; } }

    &__background {
        position: relative;
        &::after {
            @include box-shadow($bs-md rgba($c-forth, $o-mr));
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: $val-xs + 4px;
            z-index: 1;
            background-color: $c-light;
            border-radius: $br-md; } }

    &__wrap {
        scrollbar-width: thin;
        &::-webkit-scrollbar-thumb {
            background-color: $c-dark-sm;
            border-radius: 500px; }
        &::-webkit-scrollbar-track-piece {
            border-radius: 500px; }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0; }
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px; } }
    &:not(&_max) > &__background > &__wrap {
        padding-right: $val-xs;
        overflow-x: hidden;
        overflow-y: scroll; }

    &_scroll-white > &__background > &__wrap {
        &::-webkit-scrollbar-track-piece {
            background: $c-light; } }
    &_scroll-gray > &__background > &__wrap {
        &::-webkit-scrollbar-track-piece {
            background: $c-light-sm; } }

    &__content {
        position: relative;
        z-index: 2;
        overflow: hidden; }

    @include media-tablet-and-up {
        &_collapsed > &__more {
            display: none; }

        &_md > &__background > &__wrap {
            max-height: 150px; }
        &_sm > &__background > &__wrap {
            //max-height: 347px
            max-height: 67vh; }
        &_lg > &__background > &__wrap {
            max-height: 400px; }
        &_mg > &__background > &__wrap {
            max-height: 100%; }

        &_auto > &__background > &__wrap {
            max-height: auto; } }

    @include media-mobile {
        &_collapsed {

            & > .ScrollContainer__background > .ScrollContainer__wrap {
                height: auto !important;
                max-height: 999999px !important;
                padding-right: 0 !important;
                overflow-y: auto !important; }

            & > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content {
                overflow-y: auto; }

            & > .ScrollContainer__background::after {
                right: 0 !important;
                border-radius: $br-lg-2; }

            & > .ScrollContainer__more {
                display: block;
                margin-top: 18px;
                cursor: pointer;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $ff-title;
                    font-size: 20px;
                    font-weight: $fw-bold;
                    color: $c-dark-lg;
                    flex-direction: row;
                    span {
                        font-weight: $fw-normal;
                        display: block;
                        margin-left: $val-mr; }
                    i {
                        margin-right: $val-sm;
                        margin-top: -2px; } } }

            // Состояния

            &:not(.opened) > .ScrollContainer__background > .ScrollContainer__wrap > .ScrollContainer__content > *:nth-child(n+9) {
                display: none; }
            &.opened > .ScrollContainer__more {
                display: none; } } } }
