//
// Общие стили
//

.btn {
    min-width: 272px;
    transition: none !important;

    // SVG-иконки
    .spp-svg {
        margin-right: $val-xs-1;
        svg {
            transform: translateY(-.08em); } }

    // Отключено
    &:disabled,
    &.disabled {
        .spp-svg {
            pointer-events: none; } }

    // Кроме светлыхы
    &:not(.btn-light) {
        // Статичное
        &:not(:hover):not(:active):not(.active):not(:disabled):not(.disabled) {
            opacity: $o-mg; }
        // Наведение
        &:hover:not(:active):not(.active):not(:disabled):not(.disabled) {
            opacity: $o-lg; }
        // Клик/активное
        &:active,
        &.active {
            opacity: 1; } }

    // Адаптив

    @include media-breakpoint-up(lg) {
        .spp-svg {
            &:not([class*='spp-svg-size']) {
                @include set-svg-size(sm1); } } }

    @include media-breakpoint-down(md) {
        font-size: $fz-lg;
        padding: $val-md-1 $val-mr;
        .spp-svg {
            &:not([class*='spp-svg-size']) {
                @include set-svg-size(sm); } } } }


@include create-buttons-shadow( ("prim", "primary", "forth") );

//
// Светлые кнопки
//

.btn-light {
    @include set-light-button-styles; }
