.FormDatepicker {
    display: inline-block;

    &.disabled {
        opacity: $o-lg;
        pointer-events: none; }

    &__label {
        @include lables-titles; }

    &__wrap {
        transition: .2s;
        position: absolute;
        z-index: $z-index-elements;
        &:not(.opened) {
            transform: translateY($val-sm);
            opacity: 0;
            pointer-events: none; } }

    &__content {
        @include box-shadow($bs-lg rgba($c-forth, $o-mr));
        position: relative;
        min-width: 170px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }

    &__open {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $ff-title;
        font-size: $fz-sm;
        font-weight: $fw-bold;
        .spp-svg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: $val-xs;
            svg {
                height: 18px; } }

        &:not(.selected) {
            color: $c-dark-lg;
            .date {
                display: none; }
            &:hover {
                color: rgba($c-dark, $o-xl); }
            &:not(:hover) svg {
                opacity: $o-xl; } }

        &.selected {
            @include set-svg-color("prim");
            color: $c-dark;
            .placeholder {
                display: none; } } }

    &:not(&_icon) &__open .spp-svg {
        height: $val-md-1;
        width: $val-md-1;
        min-height: $val-md-1;
        min-width: $val-md-1;
        background-color: $c-light;
        border-radius: 500px; }


    @include media-breakpoint-up(lg) {
        &__content {
            border: $bw-xs solid $c-light-sm;
            border-radius: $br-md;
            background-color: $c-light;
            & > *:not(.mobile-modal__buttons) {
                padding: $val-sm-2; } }

        &__title {
            display: none !important; }

        &__buttons {
            display: none !important; } }

    @include media-breakpoint-down(md) {
        &__wrap {
            display: block !important; }

        &__title {
            margin-bottom: $val-lg; } } }
