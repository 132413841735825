.ObjectsRight {

    .Objects__item {
        &.active {
            span {
                position: relative;
                padding-left: $val-mr + $val-mr-2;
                display: block;
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: $val-mr-2;
                    height: $val-mr-2;
                    border-radius: 500px;
                    background-color: $c-third;
                    transform: translateY(-50%); } } } } }
