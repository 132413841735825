.FormInputText {
    &__comment {
        font-family: $ff-title;
        color: $c-dark-lg;
        font-weight: $fw-normal;
        font-size: $fz-xs;
        margin-top: $val-sm-2;

 } }        // @include media-breakpoint-up(lg)


