.spp-btn {
    font-family: $ff-title;
    font-size: $fz-sm;
    font-weight: $fw-bold;
    height: 2em;
    padding: 0;
    margin: 0;
    outline: none !important;
    border: 0;
    background-color: transparent;

    // SVG-иконки
    .spp-svg {
        &:not([class*='spp-svg-size']) {
            @include set-svg-size(sm1); } }

    // Если не только иконка
    &:not(&_icon) .spp-svg {
        margin-right: $val-mr;
        svg {
            transform: translateY(-.08em); } }

    // Обычный
    &:not(.light) {
        color: $c-dark;
        .spp-svg:not([class*='spp-svg-color']) {
            @include set-svg-color('dark'); }

        &:not(.active):not(:active) {
            color: $c-dark-lg; }

        &:hover:not(.active):not(:active) {
            .spp-svg {
                opacity: $o-lg; } }

        &.active, &:active {
            color: $c-dark; } }

    // В инверсии
    &.light {
        color: $c-light;
        .spp-svg:not([class*='spp-svg-color']) {
            @include set-svg-color('light'); }

        &:not(.active), &:not(:active) {
            color: transparentize($c-light, 1 - $o-md); }

        &:hover:not(.active):not(:active) {
            .spp-svg {
                opacity: $o-lg; } }

        &.active, &:active {
            color: $c-light; } }


    &.disabled, &:disabled {
        opacity: $o-xs;
        pointer-events: none; } }

